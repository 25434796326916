import { Box } from '@missionlabs/smartagent-app-components'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import Search from 'images/search.svg'
import React, { useEffect, useRef, useState } from 'react'
import { H } from 'react-accessible-headings'
import { useDispatch, useSelector } from 'react-redux'
import { getCallRecording } from 'services/api/api.contact'
import { accessScreenRecording } from 'store/contactSearch/contactSearch.actions'
import { selectScreenRecordingURL } from 'store/contactSearch/contactSearch.selectors'
import RootState from 'store/state'
import { AudioPlayer } from './AudioPlayer'
import { AudioPlayerError, ICallRecordingData } from './interfaces/AudioPlayer'

interface Props {
    contactID?: string
    recordingDate?: number
    widgetTitle?: string
}

export const CallRecording: React.FC<Props> = (props) => {
    const [haveDoneInitialLoad, setHaveDoneInitialLoad] = useState<boolean>(false)
    const [expirationErrorCount, setExpirationErrorCount] = useState<number>(0)
    const [requestCount, setRequestCount] = useState<number>(0)
    const [persistentElapsed, setPersistentElapsed] = useState<number | null>(null)
    // REFS
    const [containerRef, setContainerRef] =
        useState<React.MutableRefObject<HTMLDivElement | null> | null>(null)
    const [videoRef, setVideoRef] =
        useState<React.MutableRefObject<HTMLVideoElement | null> | null>(null)
    const [audioRef, setAudioRef] = useState<React.RefObject<HTMLAudioElement> | null>(null)
    const [ch1Muted, setCh1Muted] = useState(false)
    const [ch2Muted, setCh2Muted] = useState(false)
    const [isFullScreen, toggleFullScreen] = useState(false)
    // Refs for audio nodes
    const audioContextRef = useRef<AudioContext | null>(null)
    const sourceRef = useRef<MediaElementAudioSourceNode | null>(null)
    const splitterRef = useRef<ChannelSplitterNode | null>(null)
    const mergerRef = useRef<ChannelMergerNode | null>(null)
    const leftGainRef = useRef<GainNode | null>(null)
    const rightGainRef = useRef<GainNode | null>(null)

    const { contactID, recordingDate } = useSelector<
        RootState,
        { contactID: string; recordingDate: number }
    >((state) => ({
        contactID: props.contactID ?? state.contact?.ID ?? '',
        recordingDate: props.recordingDate ?? state.contact?.initiationTimestamp ?? 0,
    }))

    const dispatch = useDispatch()
    const hasFeature = useHasFeature()

    const [error, loading, response, request] = useSmartAgentAPI<ICallRecordingData, RootState>(
        async (state) =>
            getCallRecording(
                state.app.ID,
                state.app.instance!.ID,
                state.auth.token!,
                contactID,
                recordingDate,
            ),
        false,
    )

    const makeRequest = () => {
        if (requestCount <= 3) {
            request()
            setRequestCount((prevCount) => prevCount + 1)
        }
    }

    /**
     * The  function sets up an AudioContext and creates an audio graph for processing audio channels.
     * @returns The function returns nothing (`undefined`) if the `audioRef` is not available or not set.
     */
    const initAudioContext = () => {
        if (!audioRef?.current) return

        // create a new AudioContext if necessary
        const audioContext = new AudioContext()
        audioContextRef.current = audioContext

        const source = audioContext.createMediaElementSource(audioRef.current)
        sourceRef.current = source

        const splitter = audioContext.createChannelSplitter(2)
        const merger = audioContext.createChannelMerger(2)

        const leftGain = audioContext.createGain()
        const rightGain = audioContext.createGain()

        source.connect(splitter)
        splitter.connect(leftGain, 0)
        splitter.connect(rightGain, 1)
        leftGain.connect(merger, 0, 0)
        rightGain.connect(merger, 0, 1)
        merger.connect(audioContext.destination)

        // update refs for muting/unmuting
        splitterRef.current = splitter
        mergerRef.current = merger
        leftGainRef.current = leftGain
        rightGainRef.current = rightGain

        // set initial mute state for both channels
        leftGain.gain.setValueAtTime(ch1Muted ? 0 : 1, audioContext.currentTime)
        rightGain.gain.setValueAtTime(ch2Muted ? 0 : 1, audioContext.currentTime)
    }

    useEffect(() => {
        if (contactID) {
            AppFeatures.CALL_RECORDING && dispatch(accessScreenRecording(contactID, recordingDate))
            makeRequest()
        }
    }, [contactID])

    useEffect(() => {
        if (error) {
            makeRequest()
        }
    }, [error])

    // initialises the audio context and audio graph once audio is available
    useEffect(() => {
        initAudioContext()

        return () => {
            if (audioContextRef.current) {
                if (audioContextRef.current.state !== 'closed') {
                    audioContextRef.current.close()?.catch((error) => {
                        console.warn('Error closing AudioContext:', error)
                    })
                }
            }
        }
    }, [audioRef])

    // updates gain nodes when muting/unmuting
    useEffect(() => {
        if (leftGainRef.current && rightGainRef.current && audioContextRef.current) {
            leftGainRef.current.gain.setValueAtTime(
                ch1Muted ? 0 : 1,
                audioContextRef.current.currentTime,
            )
            rightGainRef.current.gain.setValueAtTime(
                ch2Muted ? 0 : 1,
                audioContextRef.current.currentTime,
            )
        }
    }, [ch1Muted, ch2Muted])

    const onAudioError = (err: AudioPlayerError) => {
        const signedURLHasProbablyExpired = err.statusCode === 401 || err.statusCode === 403
        if (signedURLHasProbablyExpired) {
            setExpirationErrorCount(expirationErrorCount + 1)
            if (expirationErrorCount < 3) {
                //Prevent an endless loop if URL invalid.
                request()
            }
        }
    }

    if (loading && !haveDoneInitialLoad) {
        //Avoid unmounting the player on URL refreshes.
        return <p>{loading}</p>
    } else if (!haveDoneInitialLoad) {
        setHaveDoneInitialLoad(true)
    }

    const headerTitle = props.widgetTitle ? props.widgetTitle : 'Call Recording'
    const screenRecordingURL = useSelector(selectScreenRecordingURL)

    return !!error || expirationErrorCount >= 3 ? (
        <Box alt collapse hidden={!!error} header={<H>{headerTitle}</H>}>
            <div className="sa-loading no-data">
                <img src={Search} alt="Search" width="24px" height="24px" />
                <p>Unable to retrieve call recording data</p>
            </div>
        </Box>
    ) : response?.status === 200 ? (
        <AudioPlayer
            {...response.data}
            screenRecordingURL={screenRecordingURL}
            contactID={contactID}
            recordingDate={recordingDate}
            canDownload={hasFeature(
                AppFeatures.CALL_RECORDING,
                AppSubFeatures.CALL_RECORDING_DOWNLOAD,
            )}
            onAudioError={onAudioError}
            widgetTitle={headerTitle}
            persistentElapsed={persistentElapsed}
            setPersistentElapsed={setPersistentElapsed}
            refs={{
                audioContextRef,
                leftGainRef,
                rightGainRef,
                audioRef,
                videoRef,
                containerRef,
                setContainerRef,
                setVideoRef,
                setAudioRef,
            }}
            mutedState={{ ch1Muted, setCh1Muted, ch2Muted, setCh2Muted }}
            fullScreen={{ isFullScreen, toggleFullScreen }}
        />
    ) : null
}
