import AuthState from 'store/auth/auth.state'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import UserState, {
    ForgotPasswordConfirmAction,
    LoginAction,
    NewPasswordRequiredChangeAction,
    StatusControl,
    timeRange,
    UserAuthenticatedAction,
} from './user.state'

type State = UserState | null
const initialState = null as State

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLoaded(state, action: PayloadAction<Partial<UserState>>) {
            return { ...state!, ...action.payload }
        },
        getQuickConnects(state, action: PayloadAction<any[] | undefined>) {
            return { ...state!, connects: action.payload }
        },
        userAvailable(state) {
            return { ...state!, afterCallWork: false, error: false }
        },
        afterCallWork(state) {
            return { ...state!, afterCallWork: true }
        },
        afterCallWorkEnd(state) {
            return { ...state!, afterCallWork: false }
        },
        callIssue(state) {
            return { ...state!, callIssue: true }
        },
        showQuickConnects(state) {
            return { ...state!, showConnects: true }
        },
        toggleQuickConnects(state) {
            return { ...state!, showConnects: !state!.showConnects }
        },
        hideQuickConnects(state) {
            return { ...state!, showConnects: false }
        },
        userError(state) {
            return { ...state!, error: true }
        },
        refresh(state) {
            return { ...state!, error: false }
        },
        fetchAgentMetricsRedesigned(state, action: PayloadAction<Array<any>>) {
            return { ...state!, agentContactStatsByQueueV2: action.payload }
        },
        fetchAgentStats(state, action: PayloadAction<Array<any>>) {
            return { ...state!, agentContactStatsByQueue: action.payload }
        },
        setTimeRange(state, action: PayloadAction<timeRange>) {
            return { ...state!, timeRange: action.payload }
        },
        userSetState(state, _action: PayloadAction<{ state: connect.AgentStateDefinition }>) {
            return { ...state!, inStateSince: new Date() }
        },
        setAfterCallStatus(state, action: PayloadAction<connect.AgentStateDefinition>) {
            return { ...state!, afterCallStatus: action.payload }
        },
        userAuthenticated(state, action: PayloadAction<UserAuthenticatedAction>) {
            if (!state) return state
            console.log('authenticated')
            return { ...state, agentID: action.payload.agentID }
        },
        setLogoutCountdown(state, action: PayloadAction<number>) {
            return { ...state!, logoutCountDown: action.payload }
        },
        setShowNextStatus(state, action: PayloadAction<boolean>) {
            return { ...state!, showNextStatus: action.payload }
        },
        setStatusControl(state, action: PayloadAction<StatusControl>) {
            return { ...state!, statusControl: action.payload }
        },
        forgotPassword() {},
        authenticatingUser() {},
        userNotAvailable() {},
        validatingToken() {},
        requestingToken() {},
        tokenRequested() {},
        tokenError() {},
        newPasswordRequired() {},
        rememberedPassword() {},
        userAuthenticationHasSet() {},
        authExternalUserRequest(
            _state,
            _action: PayloadAction<{ credentials: any; ccpURL: string }>,
        ) {},
        forgotPasswordGetCode(_state, _action: PayloadAction<string>) {},
        userAuthError(_state, _action: PayloadAction<{ error: string }>) {},
        forgotPasswordConfirm(_state, _action: PayloadAction<ForgotPasswordConfirmAction>) {},
        forgotPasswordSuccess() {},
        newPasswordRequiredChange(
            _state,
            _action: PayloadAction<NewPasswordRequiredChangeAction>,
        ) {},
        submitMFA(_state, _action: PayloadAction<string>) {},
        mfaRequired(_state, _action: PayloadAction<NonNullable<AuthState['mfa']>>) {},
        login(_state, _action: PayloadAction<LoginAction | undefined>) {},
        logout() {
            return null
        },
    },
})

export const {
    userLoaded,
    getQuickConnects,
    userAvailable,
    userNotAvailable,
    afterCallWork,
    afterCallWorkEnd,
    login,
    tokenError,
    callIssue,
    showQuickConnects,
    requestingToken,
    tokenRequested,
    validatingToken,
    toggleQuickConnects,
    hideQuickConnects,
    userError,
    refresh,
    fetchAgentStats,
    setTimeRange,
    authenticatingUser,
    userSetState,
    userAuthError,
    setAfterCallStatus,
    userAuthenticationHasSet,
    userAuthenticated,
    authExternalUserRequest,
    setLogoutCountdown,
    setShowNextStatus,
    setStatusControl,
    mfaRequired,
    forgotPassword,
    forgotPasswordGetCode,
    submitMFA,
    forgotPasswordConfirm,
    forgotPasswordSuccess,
    rememberedPassword,
    newPasswordRequired,
    newPasswordRequiredChange,
    logout,
    fetchAgentMetricsRedesigned,
} = userSlice.actions

export type UserAction = ReturnType<(typeof userSlice.actions)[keyof typeof userSlice.actions]>

export type LogoutAction = ReturnType<typeof logout>

export default userSlice.reducer
