import { RemoteVideos } from 'amazon-chime-sdk-component-library-react'
import { ForwardedRef, forwardRef } from 'react'
import { H } from 'react-accessible-headings'
import styled from 'styled-components'

import styles from './index.module.scss'

export interface RemoteVideoProps {
    isFullScreen: boolean
    statusMessage: string
}

const StyledVideo = styled(RemoteVideos)`
    * {
        object-fit: contain !important;
        aspect-ratio: 16 / 9 !important;
        border-radius: 10px;
    }
`

export const RemoteVideo = forwardRef(
    (props: RemoteVideoProps, videoRef: ForwardedRef<HTMLDivElement>) => {
        return (
            <div
                className={styles.RemoteVideo}
                style={{
                    height: props.isFullScreen ? '100%' : '50vh',
                }}
                ref={videoRef}
            >
                <H data-testid={'status-header'}>{props.statusMessage}</H>
                <StyledVideo
                    className={
                        props.isFullScreen ? styles.videoContainerFull : styles.videoContainer
                    }
                />
            </div>
        )
    },
)
