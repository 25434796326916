import { ContactInfo, ContactItem } from 'components'
import { ReactComponent as TaskFilledIcon } from 'images/task-icon-filled.svg'
import { ReactComponent as TaskIcon } from 'images/task-icon.svg'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedTask } from 'store/tasks/tasks.actions'
import { ITask } from 'store/tasks/tasks.state'
import { channelDisplayMap, getFormattedChannelName } from 'utils'

import styles from './TaskListItem.module.scss'

interface TaskWithNewMessageCount extends ITask {
    newMessageCount?: number
}

interface Props {
    task: TaskWithNewMessageCount
    selected: boolean
    index?: number
}

const TaskListItem: React.FC<Props> = ({ task, selected, index }) => {
    const dispatch = useDispatch()

    const onSelectTask = () => {
        dispatch(setSelectedTask(task.id))
    }

    const shouldShowCustomTaskData = Boolean(
        task.attributes?.['sa-task-display-name']?.value ||
            task.attributes?.['sa-task-display-description']?.value,
    )

    const taskName = task.attributes?.['sa-task-display-name']?.value || task.name
    const taskDisplayDescription =
        task.attributes?.['sa-task-display-description']?.value ??
        getFormattedChannelName({ channel: task.channel, defaultValue: channelDisplayMap.TASK })

    return (
        <ContactItem
            onSelect={onSelectTask}
            acw={task.acw}
            selected={selected}
            disabled={task.status === connect.ContactStateType.CONNECTING}
            mainContentClassName={shouldShowCustomTaskData ? styles['wrapper-full-width'] : null}
            ariaLabel={`Contact card ${task.channel || 'Task'} from`}
            contactName={taskName}
            description={taskDisplayDescription}
            descriptionMobileHidden
            icon={
                selected ? (
                    <TaskFilledIcon
                        title="Task"
                        data-testid="task-icon-filled"
                        aria-hidden={true}
                    />
                ) : (
                    <TaskIcon title="Task" data-testid="task-icon" aria-hidden={true} />
                )
            }
            index={index}
        >
            {!shouldShowCustomTaskData && (
                <ContactInfo
                    isSelected={selected}
                    contactTimeStarted={task.started}
                    acw={task.acw}
                />
            )}

            {!selected && task.newMessageCount && (
                <div className={`${styles.newMessageCount}`}>
                    <span className="screen-reader-only">{`${task.newMessageCount} new ${task.newMessageCount > 1 ? 'messages' : 'message'} from email`}</span>
                    <p aria-hidden="true">{task.newMessageCount}</p>
                </div>
            )}
        </ContactItem>
    )
}

export default TaskListItem
