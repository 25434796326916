import { Dropdown } from '@missionlabs/smartagent-app-components'
import { useEffect, useState } from 'react'
import { calculateTimeZoneOffsetInHours, constructTimeZoneOffsetSign } from 'utils'
import styles from './TimezoneDropdown.module.scss'
import timeZones from './timeZones.json'

interface Props {
    defaultValue?: string
    onChange: (timezone: string) => void
}

type Option = { label: string; data: string }

const constructLabel = (date: string, timeZone: string) => {
    const offsetInHours = calculateTimeZoneOffsetInHours(date, timeZone)

    return `${timeZone} (GMT${constructTimeZoneOffsetSign(
        offsetInHours,
    )}${Math.abs(offsetInHours)})`
}

const prepareOptions = (timeZones: string[]) => {
    const utcDate = new Date().toISOString()

    return timeZones.map((timeZone) => {
        return {
            label: constructLabel(utcDate, timeZone),
            data: timeZone,
        }
    })
}

const TimezoneDropdown = ({ onChange, defaultValue }: Props) => {
    const [isDataProcessing, setIsDataProcessing] = useState(true)
    const [options, setOptions] = useState<Option[]>([])
    const [selectedLabel, setSelectedLabel] = useState(defaultValue ?? '')

    useEffect(() => {
        const dropdownOptions = prepareOptions(timeZones)

        setOptions(dropdownOptions)
        setIsDataProcessing(false)
    }, [])

    const handleOnChange = (value: Option) => {
        setSelectedLabel(value.label)
        onChange(value.data)
    }

    return (
        <Dropdown
            filter
            placeholder="Select a time zone"
            aria-label="Select a time zone"
            data-testid={'timezone-dropdown'}
            options={options}
            onChange={handleOnChange}
            className={styles.dropdown}
            disabled={isDataProcessing}
            value={selectedLabel}
        />
    )
}

export default TimezoneDropdown
