import { Button } from '@missionlabs/smartagent-app-components'
import { useDispatch } from 'react-redux'
import { saveScreenshot } from 'store/videoMeeting/videoMeeting.reducer'
import { useTakeSnapshot } from '../hooks/useTakeSnapshot'

interface ScreenshotButtonProps {
    videoRef: React.RefObject<HTMLVideoElement>
    isHistorical?: boolean
    disabled?: boolean
    attributeAttachmentCount?: string
}

export const ScreenshotButton = ({
    videoRef,
    isHistorical = false,
    disabled = false,
    attributeAttachmentCount,
}: ScreenshotButtonProps) => {
    const dispatch = useDispatch()
    const { takeSnapshot } = useTakeSnapshot(videoRef)
    const takeScreenshot = () => {
        dispatch(
            saveScreenshot({
                imageData: takeSnapshot() ?? '',
                isHistorical,
                attributeAttachmentCount: attributeAttachmentCount ?? '',
            }),
        )
    }
    return (
        <Button
            styling="white"
            title="Take Screenshot"
            elastic
            onClick={takeScreenshot}
            style={{
                width: '130px',
                margin: '10px 5px 0px 5px',
            }}
            disabled={disabled}
        >
            Take Screenshot
        </Button>
    )
}
