import { Button, Dropdown, TimeElapsed } from '@missionlabs/smartagent-app-components'
import React from 'react'
import { ReactZoomPanPinchHandlers } from 'react-zoom-pan-pinch'
import ExitFullScreenIcon from '../../images/icon-exit-fullscreen.svg'
import FullScreenIcon from '../../images/icon-fullscreen.svg'
import ZoomInIcon from '../../images/icon-zoom-in.svg'
import ZoomOutIcon from '../../images/icon-zoom-out.svg'
import ZoomResetIcon from '../../images/icon-zoom-reset.svg'
import PauseIcon from './Pause-noFill.svg'
import PlayIcon from './Play-noFill.svg'
import { PlaybackRateOption } from './interfaces/AudioPlayer'
import './video-controls.scss'

interface Props {
    videoZoomControls: ReactZoomPanPinchHandlers | null
    playbackState: {
        playPause(): void
        playing: boolean
        setPlaying: React.Dispatch<React.SetStateAction<boolean>>
        duration: number
        setElapsed: React.Dispatch<React.SetStateAction<number>>
        elapsed?: number
        playbackRateOptions: PlaybackRateOption[]
        playbackRateOnChange: (option: any) => void
        currentPlaybackOption: PlaybackRateOption
        setPersistentElapsed: React.Dispatch<React.SetStateAction<number | null>>
    }
    fullScreen: {
        isFullScreen: boolean
        toggleFullScreen: React.Dispatch<React.SetStateAction<boolean>>
    }
}

interface ControlType {
    icon: string
    dataTestId: string
    alt: string
    title: string
    onClick: () => void
    disabled?: boolean
}

export const VideoControls: React.FC<Props> = ({
    fullScreen,
    playbackState,
    videoZoomControls,
}) => {
    const { isFullScreen, toggleFullScreen } = fullScreen
    const {
        playing: isPlaying,
        setPlaying: setIsPlaying,
        elapsed,
        setPersistentElapsed,
        duration,
        playbackRateOptions,
        playbackRateOnChange,
        currentPlaybackOption,
        playPause,
    } = playbackState

    const handleFullScreen = () => {
        toggleFullScreen(!isFullScreen)

        if (elapsed) {
            setPersistentElapsed(elapsed)
        }

        if (isPlaying) {
            setIsPlaying(false)
        }
    }

    if (!videoZoomControls) return null

    const controls: ControlType[] = [
        {
            icon: ZoomInIcon,
            dataTestId: 'zoom-in-button',
            title: 'Zoom In',
            alt: 'Zoom In',
            onClick: () => videoZoomControls.zoomIn(),
        },
        {
            icon: ZoomOutIcon,
            dataTestId: 'zoom-out-button',
            title: 'Zoom Out',
            alt: 'Zoom Out',
            onClick: () => videoZoomControls.zoomOut(),
        },
        {
            icon: ZoomResetIcon,
            dataTestId: 'zoom-reset-button',
            title: 'Zoom Reset',
            alt: 'Zoom Reset',
            onClick: () => {
                videoZoomControls.centerView()
                videoZoomControls.setTransform(0, 0, 1)
            },
        },

        {
            icon: isFullScreen ? ExitFullScreenIcon : FullScreenIcon,
            dataTestId: 'fullscreen-button',
            title: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
            alt: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
            onClick: handleFullScreen,
        },
    ]

    return (
        <div
            className="sa-videoplayer-controls-wrapper"
            data-testid="screen-recording-video-controls"
        >
            <div className="sa-videoplayer-controls">
                <div className="timeelapsed-wrapper">
                    <TimeElapsed
                        ariaLive="off"
                        hours
                        format="hh:mm:ss"
                        noTick
                        date={TimeElapsed.from(elapsed ?? 0)}
                    />
                    <span>{' / '}</span>
                    <TimeElapsed
                        ariaLive="off"
                        hours
                        format="hh:mm:ss"
                        noTick
                        date={TimeElapsed.from(duration)}
                    />
                </div>

                <Button
                    data-testid="play-pause-button-video"
                    style={{ margin: '0px 3px' }}
                    onClick={playPause}
                >
                    <img
                        src={isPlaying ? PauseIcon : PlayIcon}
                        alt={isPlaying ? 'Pause' : 'Play'}
                        title={isPlaying ? 'Pause' : 'Play'}
                    />
                </Button>

                <Dropdown
                    options={playbackRateOptions}
                    onChange={playbackRateOnChange}
                    value={currentPlaybackOption?.label || ''}
                />

                {controls.map(({ icon, alt, title, onClick, disabled, dataTestId }, index) => {
                    return (
                        <Button
                            data-testid={dataTestId}
                            key={`${index}-${icon}`}
                            disabled={disabled}
                            onClick={onClick}
                        >
                            <img src={icon} alt={alt} title={title} />
                        </Button>
                    )
                })}
            </div>
        </div>
    )
}
