import { createSelector } from 'reselect'
import RootState from 'store/state'

const selectUserState = (state: RootState) => state.user

export const selectSortedUserQueues = createSelector([selectUserState], (user) => {
    return [...(user?.queues || [])].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    )
})
export const selectUserStatus = createSelector([selectUserState], (user) => user?.status)
