import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    forgetHistoricalMeeting,
    getMeetingRecording,
    getScreenshots,
    selectHistoricalScreenshots,
    selectPlaybackURL,
} from 'store/videoMeeting/videoMeeting.reducer'
import { Screenshots } from 'store/videoMeeting/videoMeeting.state'

export interface HistoricSessionMedia {
    playbackURL: string
    screenshots: Screenshots
}

export interface HistoricSessionMediaProps {
    meetingID: string
    contactID: string
}

export const POLLING_INTERVAL = 10_000 // milliseconds
export const MAX_RETRY_COUNT = 8

export const useHistoricSessionMedia = ({
    meetingID,
    contactID,
}: HistoricSessionMediaProps): HistoricSessionMedia => {
    const dispatch = useDispatch()

    const playbackURL = useSelector(selectPlaybackURL) ?? ''
    const screenshots = useSelector(selectHistoricalScreenshots) ?? {}

    const getRecordingInterval = useRef<number | undefined>(undefined)

    const periodicallyCheckForPlaybackURL = (meetingID: string) => {
        let retryCount = 0

        dispatch(getMeetingRecording({ meetingID, displayNotificationOnFailure: false }))

        getRecordingInterval.current = setInterval(() => {
            retryCount++

            const isLastAttempt = retryCount === MAX_RETRY_COUNT

            dispatch(
                getMeetingRecording({ meetingID, displayNotificationOnFailure: isLastAttempt }),
            )

            if (isLastAttempt) {
                clearInterval(getRecordingInterval.current)
            }
        }, POLLING_INTERVAL) as unknown as number

        return getRecordingInterval.current
    }

    useEffect(() => {
        if (getRecordingInterval.current) {
            clearInterval(getRecordingInterval.current)
        }
    }, [playbackURL])

    useEffect(() => {
        dispatch(forgetHistoricalMeeting())

        if (!meetingID || !contactID) return

        const interval = periodicallyCheckForPlaybackURL(meetingID)
        dispatch(getScreenshots({ isHistorical: true, meetingID, contactID }))

        return () => clearInterval(interval)
    }, [meetingID, contactID])

    return {
        playbackURL,
        screenshots,
    }
}
