import { Button } from '@missionlabs/smartagent-app-components'
import { Loader } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { selectContact } from 'store/contact/contact.selectors'
import { createMeeting, selectMeeting } from 'store/videoMeeting/videoMeeting.reducer'
import { VideoMeetingLoadingStatus } from 'store/videoMeeting/videoMeeting.state'
import styles from './index.module.scss'

export const CreateSession = () => {
    const dispatch = useDispatch()
    const contact = useSelector(selectContact)
    const videoMeeting = useSelector(selectMeeting)
    const loading = videoMeeting.loadingStatus === VideoMeetingLoadingStatus.LOADING

    const handleClick = () => {
        if (!contact) return
        dispatch(createMeeting({ contactID: contact?.ID }))
    }

    return (
        <Button
            styling="primary"
            title="Create Meeting"
            elastic
            className={styles.button}
            onClick={handleClick}
            disabled={!contact?.ID || loading}
        >
            <span className={['xsm-mar-right', styles.icon].join(' ')}></span>
            {loading ? (
                <Loader size={16} color="#fff" loaderType="clip" className="sm-pad" />
            ) : (
                'Request Live Video Assist'
            )}
        </Button>
    )
}
