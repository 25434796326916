import React from 'react'

import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import { Widgets, WidgetType } from 'views/Home/Widgets'
import { IInteraction } from '../../types'

import './voice-view.scss'

export interface Props {
    interaction: IInteraction
}

const VoiceView: React.FC<Props> = ({ interaction }) => {
    const hasFeature = useHasFeature()

    const {
        ID: interactionID,
        initiationTimestamp,
        disconnectTimestamp,
        connectedToAgentTimestamp,
        attributesUpdated = false,
    } = interaction

    // Render a column out if the user has permissions for any of its features
    const renderLeftColumn =
        hasFeature(AppFeatures.CALL_DETAILS) ||
        hasFeature(AppFeatures.CALL_RECORDING) ||
        hasFeature(AppFeatures.CALL_COMPREHENSION) ||
        hasFeature(AppFeatures.QA) ||
        hasFeature(AppFeatures.VIDEO_MEETING)

    const renderRightColumn =
        hasFeature(AppFeatures.CALL_TRANSCRIPTION) || hasFeature(AppFeatures.VIDEO_MEETING)

    return (
        <Widgets
            type="callContact"
            render={(getWidget) => (
                <div className="voice-view-container">
                    {renderLeftColumn && (
                        <div className="voice-view-container-left">
                            {getWidget(AppFeatures.CALL_DETAILS, {
                                contact: attributesUpdated ? interaction : undefined,
                            })}
                            {getWidget(AppFeatures.CALL_RECORDING, {
                                contactID: interactionID,
                                recordingDate: initiationTimestamp,
                            })}
                            {getWidget(AppFeatures.CALL_COMPREHENSION, {
                                contactID: interactionID,
                                disconnectTimestamp,
                                connectedToAgentTimestamp,
                            })}
                            {getWidget(WidgetType.QA_EVALUATION_STATUS, {
                                contactID: interactionID,
                            })}
                        </div>
                    )}
                    {renderRightColumn && (
                        <div className="voice-view-container-right">
                            {getWidget(AppFeatures.CALL_TRANSCRIPTION, {
                                contactID: interactionID,
                                initiationTimestamp,
                                disconnectTimestamp,
                            })}
                            {getWidget(AppFeatures.VIDEO_MEETING, {
                                contact: interaction,
                            })}
                        </div>
                    )}
                </div>
            )}
        />
    )
}

export default VoiceView
