import { Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import style from './index.module.scss'

interface ConfirmationModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    message: string
    confirmLabel?: string
    cancelLabel?: string
    title?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    message,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    title,
}) => {
    return (
        <div className={style['GreyOverlay']}>
            <div
                className={classNames(style['modal'], isOpen ? style.open : style.closed)}
                role="dialog"
                aria-modal="true"
            >
                <div className={style['modal-content']}>
                    {title && <h2>{title}</h2>}
                    <p className={style.Message}>{message}</p>
                    <div className={style.ModalButtons}>
                        <Button
                            styling="simple"
                            onClick={onClose}
                            className={style.Button}
                            title="Cancel"
                        >
                            {cancelLabel}
                        </Button>
                        <Button
                            styling="red"
                            onClick={onConfirm}
                            className={style.Button}
                            title="Confirm"
                        >
                            {confirmLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal
