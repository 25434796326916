import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    CreateMeetingAction,
    CreateMeetingFailedAction,
    CreateMeetingSuccessAction,
    DeleteScreenshotsAction,
    GetMeetingRecordingAction,
    GetScreenshotsAction,
    MeetingViews,
    ModifyScreenshotsAction,
    SaveScreenshotFailedAction,
    SaveScreenshotAction as SaveScreenshotStartAction,
    SaveScreenshotSuccessAction,
    SetMeetingInvitedSMSAction,
    SetMeetingNavigateAction,
    SetMeetingReqLoadingAction,
    SetMeetingStatus,
    VideoMeetingLoadingStatus,
    VideoMeetingState,
} from './videoMeeting.state'
import { addScreenshot, deleteInFlightScreenshot, modifyScreenshots } from './videoMeeting.utils'

const initialState: VideoMeetingState = {
    loadingStatus: VideoMeetingLoadingStatus.IDLE,
    view: MeetingViews.CREATE,
    meeting: null,
    agentAttendee: null,
    contactID: null,
    smsInviteSentTo: null,
    isDeleteInProgress: false,
    isInviteInProgress: false,
    status: 'PENDING',
}

export const videoMeetingSlice = createSlice({
    name: 'videoMeeting',
    initialState,
    reducers: {
        createMeeting(state: VideoMeetingState, action: PayloadAction<CreateMeetingAction>) {
            return {
                ...state,
                contactID: action.payload.contactID,
            }
        },
        createMeetingStart(state: VideoMeetingState) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.LOADING,
            }
        },
        createMeetingSuccess(
            state: VideoMeetingState,
            action: PayloadAction<CreateMeetingSuccessAction>,
        ) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.IDLE,
                ...action.payload,
            }
        },
        createMeetingFailed(
            state: VideoMeetingState,
            action: PayloadAction<CreateMeetingFailedAction>,
        ) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.ERROR,
                contactID: null,
                smsInviteSentTo: null,
                errorMessage: action.payload.message,
            }
        },
        setMeetingInvitedSMS(
            state: VideoMeetingState,
            action: PayloadAction<SetMeetingInvitedSMSAction>,
        ) {
            return {
                ...state,
                smsInviteSentTo: action.payload.smsInviteSentTo,
            }
        },
        setMeetingReqLoading(
            state: VideoMeetingState,
            action: PayloadAction<SetMeetingReqLoadingAction>,
        ) {
            return {
                ...state,
                isInviteInProgress: !!action.payload.isInviteInProgress,
                isDeleteInProgress: !!action.payload.isDeleteInProgress,
            }
        },
        setMeetingView(state: VideoMeetingState, action: PayloadAction<SetMeetingNavigateAction>) {
            return {
                ...state,
                view: action.payload.view,
            }
        },
        setMeetingStatus(state, action: PayloadAction<SetMeetingStatus>) {
            return {
                ...state,
                status: action.payload.status,
            }
        },
        deleteMeeting(state, _action: PayloadAction<void>) {
            return { ...state, meeting: null }
        },
        forgetMeeting(_state, _action: PayloadAction<void>) {
            return initialState
        },
        forgetHistoricalMeeting(state) {
            return { ...state, historicalMeeting: undefined }
        },
        getMeetingRecording(state, action: PayloadAction<GetMeetingRecordingAction>) {
            return {
                ...state,
                historicalMeeting: {
                    ...state.historicalMeeting,
                    meetingID: action.payload.meetingID,
                },
            }
        },
        getMeetingRecordingSuccess(state, action: PayloadAction<string>) {
            return {
                ...state,
                historicalMeeting: {
                    ...state.historicalMeeting,
                    playbackURL: action.payload,
                },
            }
        },
        getScreenshots(state, action: PayloadAction<GetScreenshotsAction>) {
            return {
                ...state,
                historicalMeeting: {
                    ...state.historicalMeeting,
                    meetingID: action.payload.meetingID,
                    contactID: action.payload.contactID,
                },
            }
        },
        getScreenshotsCompleted(state, action: PayloadAction<ModifyScreenshotsAction>) {
            const { screenshots, isHistorical } = action.payload
            return modifyScreenshots(state, screenshots, isHistorical)
        },
        saveScreenshot(
            state: VideoMeetingState,
            _action: PayloadAction<SaveScreenshotStartAction>,
        ) {
            return {
                ...state,
            }
        },
        saveScreenshotStart(
            state: VideoMeetingState,
            action: PayloadAction<SaveScreenshotStartAction>,
        ) {
            return addScreenshot(state, {
                isHistorical: !!action.payload.isHistorical,
                s3Key: 'IN_FLIGHT',
                src: action.payload.imageData,
                attributeAttachmentCount: action.payload.attributeAttachmentCount,
            })
        },
        saveScreenshotFailed(
            state: VideoMeetingState,
            action: PayloadAction<SaveScreenshotFailedAction>,
        ) {
            return deleteInFlightScreenshot(state, !!action.payload.isHistorical)
        },
        saveScreenshotSuccess(
            state: VideoMeetingState,
            action: PayloadAction<SaveScreenshotSuccessAction>,
        ) {
            const newState = deleteInFlightScreenshot(state, action.payload.isHistorical)

            return addScreenshot(newState, action.payload)
        },
        updateVideoMeetingAttachmentCount(state: VideoMeetingState, action: PayloadAction<number>) {
            return {
                ...state,
                saVideoMeetingAttachmentCount: action.payload,
            }
        },
        deleteScreenshots(
            state: VideoMeetingState,
            _action: PayloadAction<DeleteScreenshotsAction>,
        ) {
            return { ...state }
        },
        deleteScreenshotsCompleted(
            state: VideoMeetingState,
            action: PayloadAction<ModifyScreenshotsAction>,
        ) {
            const { screenshots, isHistorical } = action.payload
            return modifyScreenshots(state, screenshots, isHistorical)
        },
    },
    selectors: {
        selectIsMeetingCreated: (state: VideoMeetingState) => {
            return state.meeting && state.contactID
        },
        getMeetingView: (state: VideoMeetingState) => {
            return state.view
        },
        selectMeeting: (state: VideoMeetingState) => {
            return state
        },
        selectPlaybackURL: (state: VideoMeetingState) => {
            return state.historicalMeeting?.playbackURL
        },
        selectHistoricalScreenshots: (state: VideoMeetingState) => {
            return state.historicalMeeting?.screenshots
        },
    },
})

export const {
    createMeeting,
    createMeetingStart,
    createMeetingSuccess,
    createMeetingFailed,
    setMeetingInvitedSMS,
    saveScreenshot,
    saveScreenshotStart,
    saveScreenshotFailed,
    saveScreenshotSuccess,
    updateVideoMeetingAttachmentCount,
    setMeetingReqLoading,
    setMeetingView,
    setMeetingStatus,
    deleteMeeting,
    forgetMeeting,
    forgetHistoricalMeeting,
    getMeetingRecording,
    getMeetingRecordingSuccess,
    deleteScreenshots,
    deleteScreenshotsCompleted,
    getScreenshots,
    getScreenshotsCompleted,
} = videoMeetingSlice.actions

export const {
    selectIsMeetingCreated,
    selectMeeting,
    getMeetingView,
    selectPlaybackURL,
    selectHistoricalScreenshots,
} = videoMeetingSlice.selectors

export type VideoMeetingAction = ReturnType<
    (typeof videoMeetingSlice.actions)[keyof typeof videoMeetingSlice.actions]
>

export default videoMeetingSlice.reducer
