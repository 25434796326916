import useCall from 'hooks/redux/useCall'
import useChatConnections from 'hooks/redux/useChatConnections'
import useContact from 'hooks/redux/useContact'
import useContacts from 'hooks/redux/useContacts'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import useContactLogForm from 'hooks/useContactLogForm'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import CallState from 'store/call/call.state'
import { IChat } from 'store/chat/chat.state'
import { taskMissed } from 'store/tasks/tasks.actions'
import { ITask } from 'store/tasks/tasks.state'
import CallListItem from 'views/Home/Dialler/CallListItem'

import { Channel } from '@aws-sdk/client-connect'
import { useSelector } from 'react-redux'
import { CTR } from 'store/contact/contact.state'
import RootState from 'store/state'
import useInterval from '../../../hooks/useInterval'
import { getThreadsNewMessageCounts } from '../../../services/api/api.thread'
import ChatsListItem from '../../Chat/ChatListItem'
import TaskListItem from '../../Tasks/TaskListItem'
import styles from './ContactList.module.scss'

interface Props {}

interface ExtendedITask extends ITask {
    contactType: string
}

interface ExtendedIChat extends IChat {
    contactType: string
}

interface ExtendedICall extends CallState {
    initiationMethod?: CTR['initiationMethod']
}

type ContactListTypes = Partial<ExtendedICall> | ExtendedIChat | ExtendedITask

interface ThreadIdWithNewMessageCount {
    threadID: string
    newMessageCount?: number
}

function expandWithTypeField<Type>(contactType: string, contactList: Type[]): Type[] {
    return contactList.length > 0
        ? contactList.map((contact) => {
              return { ...contact, contactType }
          })
        : contactList
}

const ContactList: React.FC<Props> = () => {
    //needs for loading forms if reload page not on contactLog page
    //uses for status dropdown validation
    useContactLogForm()

    const dispatch = useDispatch()

    const call = useCall()
    const showCall = call && !call.incoming
    const { AFTER_CALL_WORK } = connect.AgentAvailStates
    const chatsList = expandWithTypeField('chat', useChatConnections('current')) as ExtendedIChat[]
    const tasksList = expandWithTypeField('task', useTaskConnections('current')) as ExtendedITask[]
    const [threadsNewMessageCounts, setThreadsNewMessageCounts] = useState<
        ThreadIdWithNewMessageCount[]
    >([])

    const currentStatus = useSelector(
        (state: RootState) => state.user?.status.name,
    ) as connect.AgentAvailStates

    const contact = useContact()

    const contacts = useContacts()

    const refreshThreadsNewMessageCount = async () => {
        if (tasksList.length > 1) {
            const taskListThreadIds = tasksList
                .map((task) => task.threadID)
                .filter(Boolean) as string[]
            if (taskListThreadIds.length) {
                setThreadsNewMessageCounts(await getThreadsNewMessageCounts(taskListThreadIds))
            }
        }
    }

    useInterval(() => {
        refreshThreadsNewMessageCount()
    }, 30000)

    useEffect(() => {
        setTimeout(() => refreshThreadsNewMessageCount(), 500)
    }, [])

    useEffect(() => {
        if (tasksList.find((task) => task.status === 'connecting')) {
            refreshThreadsNewMessageCount()
        }

        const rejectedTasks = tasksList.filter(({ status }) => status === 'rejected')
        rejectedTasks.forEach(({ id }) => {
            // Like the contact.onRefresh callback in the listeners.contact.ts middleware. Marks rejected tasks as missed.
            dispatch(taskMissed(id))
        })
    }, [tasksList.length])

    useEffect(() => {
        // When we select a contact, we should remove the newMessageCount
        if (contact && contact.attributes['sa-threadID']) {
            const threadsNewMessageCountsFiltered = threadsNewMessageCounts.filter(
                (t) => t.threadID !== contact.attributes['sa-threadID'],
            )
            if (threadsNewMessageCountsFiltered.length !== threadsNewMessageCounts.length) {
                setThreadsNewMessageCounts(threadsNewMessageCountsFiltered)
            }
        }
    }, [contact?.ID])

    const allItems = useMemo(() => {
        const tasksWithNewMessageCounts = tasksList.map((task) => {
            const newMessageCount = threadsNewMessageCounts.find(
                (threadAndNewMessageCount) => threadAndNewMessageCount.threadID === task.threadID,
            )?.newMessageCount
            return newMessageCount ? { ...task, newMessageCount } : task
        })

        const items: ContactListTypes[] = [...chatsList, ...tasksWithNewMessageCounts]

        const voiceContact = contacts.find((contact) => contact.channel === Channel.VOICE)

        if (showCall) items.unshift({ ...call, initiationMethod: voiceContact?.initiationMethod, number: call.number ?? voiceContact?.customer?.phoneNumber })

        //Not in a call but agent is in ACW so still show the call in the list
        if (currentStatus === AFTER_CALL_WORK) {
            items.unshift({
                inCall: true,
                acw: true,
                number: voiceContact?.customer?.phoneNumber,
                initiationMethod: voiceContact?.initiationMethod,
            } as Partial<CallState>)
        }

        return items
    }, [chatsList, tasksList, threadsNewMessageCounts, currentStatus, call, contacts])

    const renderItem = (item: ContactListTypes, index: number) => {
        if (
            (item as CallState).connections ||
            (item as CallState).inCall ||
            (item as CallState).isMissed
        ) {
            return (
                <CallListItem
                    selected={contact?.channel === Channel.VOICE}
                    call={item as CallState}
                    index={index}
                />
            )
        } else if ((item as ExtendedIChat).contactType === 'chat') {
            const chat = item as ExtendedIChat

            return (
                <ChatsListItem
                    selected={chat.id === contact?.ID}
                    chat={chat}
                    channel={chat.socialMediaPlatform}
                    index={index}
                />
            )
        } else if ((item as ExtendedITask).contactType === 'task') {
            const task = item as ExtendedITask

            return <TaskListItem selected={task.id === contact?.ID} task={task} index={index} />
        }
    }

    const renderItemsList = (list: ContactListTypes[]) => {
        return (
            <ol className={styles.contactList} data-test-id="contactList">
                {list
                    .filter(
                        (contact) =>
                            !('status' in contact) ||
                            contact.status !== connect.ContactStateType.CONNECTING,
                    )
                    .map((item, index) => {
                        const key = (item as ExtendedIChat).id || 'call'
                        return <li key={key}>{renderItem(item, index)}</li>
                    })}
            </ol>
        )
    }

    return renderItemsList(allItems)
}

export default ContactList
