import { RefObject } from 'react'

export const useTakeSnapshot = (videoRef: RefObject<HTMLElement>) => {
    const takeSnapshot = () => {
        const video = getVideoElement(videoRef)

        if (!video) return

        const canvas = createVideoCanvas(video)

        const imageData = canvas.captureFrame()
        canvas.remove()

        return imageData
    }

    return {
        takeSnapshot,
    }
}

const getVideoElement = (videoRef: RefObject<HTMLElement>): HTMLVideoElement | undefined | null => {
    if (videoRef.current instanceof HTMLVideoElement) {
        return videoRef.current
    }

    return videoRef.current?.querySelector('video')
}

const createVideoCanvas = (video: HTMLVideoElement) => {
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.style.display = 'none'
    canvas.setAttribute('data-testid', 'snapshot-canvas')

    document.body.appendChild(canvas)

    const captureFrame = () => {
        const context = canvas.getContext('2d')

        if (!context) return

        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

        return canvas.toDataURL('image/png')
    }

    return {
        remove: () => document.body.removeChild(canvas),
        captureFrame,
    }
}
