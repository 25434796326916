import { SaveScreenshotSuccessAction, Screenshots, VideoMeetingState } from './videoMeeting.state'

export const deleteInFlightScreenshot = (state: VideoMeetingState, isHistorical = false) => {
    const screenshots = getScreenshots(state, isHistorical)

    delete screenshots.IN_FLIGHT

    return modifyScreenshots(state, screenshots, isHistorical)
}

export const addScreenshot = (state: VideoMeetingState, action: SaveScreenshotSuccessAction) => {
    const { s3Key = '', src, isHistorical } = action

    const screenshots = getScreenshots(state, isHistorical)

    screenshots[s3Key] = { src }

    return modifyScreenshots(state, screenshots, isHistorical)
}

export const modifyScreenshots = (
    state: VideoMeetingState,
    newScreenshots: Screenshots,
    isHistorical: boolean,
) => {
    return isHistorical
        ? {
              ...state,
              historicalMeeting: {
                  ...state.historicalMeeting,
                  screenshots: newScreenshots,
              },
          }
        : {
              ...state,
              screenshots: newScreenshots,
          }
}

const getScreenshots = (state: VideoMeetingState, isHistorical = false) => {
    return isHistorical
        ? { ...(state.historicalMeeting?.screenshots ?? {}) }
        : { ...state.screenshots }
}
