export const DownloadIcon = ({ color, style }: { color: string; style?: React.CSSProperties }) => {
    return (
        <span style={{ ...style, alignItems: 'center', display: 'flex' }}>
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.6663 10.3334C10.9425 10.3334 11.1663 10.5573 11.1663 10.8334C11.1663 11.0865 10.9782 11.2957 10.7342 11.3288L10.6663 11.3334H1.33301C1.05687 11.3334 0.833008 11.1096 0.833008 10.8334C0.833008 10.5803 1.02111 10.3711 1.26516 10.338L1.33301 10.3334H10.6663ZM5.99967 0.166748C6.25281 0.166748 6.462 0.354851 6.49511 0.598901L6.49967 0.666748V7.32541L8.47675 5.34873C8.65426 5.17122 8.93204 5.15508 9.12778 5.30032L9.18386 5.34873C9.37912 5.54399 9.37912 5.86057 9.18386 6.05583L6.35543 8.88426C6.16017 9.07952 5.84358 9.07952 5.64832 8.88426L2.81989 6.05583C2.62463 5.86057 2.62463 5.54399 2.81989 5.34873C3.01516 5.15347 3.33174 5.15347 3.527 5.34873L5.49967 7.32141V0.666748C5.49967 0.390606 5.72353 0.166748 5.99967 0.166748Z"
                    fill={color}
                />
            </svg>
        </span>
    )
}
