import { Button } from '@missionlabs/smartagent-app-components'
import {
    lightTheme,
    MeetingProvider,
    useMeetingManager,
    useRemoteVideoTileState,
    useRosterState,
} from 'amazon-chime-sdk-component-library-react'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import classNames from 'classnames'
import { Loader } from 'components'
import ConfirmationModal from 'components/ConfirmationModal'
import { useEffect, useRef, useState } from 'react'
import { H } from 'react-accessible-headings'
import { useDispatch, useSelector } from 'react-redux'
import { updateContactAttributes } from 'store/contact/contact.actions'
import { selectContact } from 'store/contact/contact.selectors'
import {
    deleteMeeting,
    forgetMeeting,
    selectMeeting,
    setMeetingStatus,
} from 'store/videoMeeting/videoMeeting.reducer'
import { ThemeProvider } from 'styled-components'
import { LoadingSession } from '../LoadingSession'
import { RemoteVideo } from '../RemoteVideo'
import { ScreenshotButton } from '../ScreenshotButton'
import { Screenshots } from '../Screenshots'
import styles from './index.module.scss'

export const VideoSession = () => {
    return (
        <div>
            <ThemeProvider theme={lightTheme}>
                <MeetingProvider>
                    <VideoSessionWrapped />
                </MeetingProvider>
            </ThemeProvider>
        </div>
    )
}

enum CustomerStatus {
    LEFT = 'Customer has left the session',
    CAMERA_OFF = 'Customer Camera Disabled',
}

const VideoSessionWrapped = () => {
    const dispatch = useDispatch()
    const contact = useSelector(selectContact)
    const videoMeeting = useSelector(selectMeeting)
    const meetingManager = useMeetingManager()
    const [customerLeft, setCustomerLeft] = useState(false)
    const { tiles } = useRemoteVideoTileState()
    const { roster } = useRosterState()
    const [fullscreen, setFullscreen] = useState(false)
    const isInProgress = videoMeeting.status === 'IN_PROGRESS'
    const [isModalOpen, setIsModalOpen] = useState(false)
    const videoRef = useRef(null)

    const joinMeeting = async () => {
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
            videoMeeting.meeting,
            videoMeeting.agentAttendee,
        )
        await meetingManager.join(meetingSessionConfiguration)
        await meetingManager.start()
    }

    const handleCancel = async () => {
        dispatch(deleteMeeting())
        dispatch(forgetMeeting())
    }

    const status = () => {
        if (customerLeft) return CustomerStatus.LEFT
        if (!tiles.length) return CustomerStatus.CAMERA_OFF
        return ''
    }

    const handleEnd = async () => {
        if (videoMeeting.meeting?.MeetingId) {
            dispatch(
                setMeetingStatus({
                    status: 'ENDED',
                }),
            )
            dispatch(deleteMeeting())
        }
    }
    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleConfirm = () => {
        setIsModalOpen(false)
        handleEnd()
    }

    useEffect(() => {
        joinMeeting()

        return () => {
            meetingManager.leave()
        }
    }, [])

    useEffect(() => {
        if (['ENDED'].includes(videoMeeting.status)) return

        const hasCustomer = Object.values(roster).find((user) =>
            user.externalUserId?.includes('customer'),
        )

        setCustomerLeft(!hasCustomer)

        if (isInProgress || !hasCustomer) return

        const meetingIdValue = () => {
            const list = contact?.attributes['sa-video-meeting-id']
                ? contact?.attributes['sa-video-meeting-id'].split(',')
                : []
            list.push(`${contact?.ID}_${videoMeeting.meeting!.MeetingId}`)
            return list.join(',')
        }

        dispatch(
            updateContactAttributes(
                contact!.ID,
                {
                    'sa-video-meeting-id': meetingIdValue(),
                },
                true,
            ),
        )

        dispatch(
            setMeetingStatus({
                status: 'IN_PROGRESS',
            }),
        )
    }, [roster, videoMeeting.meeting?.MeetingId, videoMeeting.status])

    if (isInProgress) {
        return (
            <div>
                <div
                    data-testid={'container-div'}
                    className={fullscreen ? styles.containerFull : styles.container}
                >
                    <RemoteVideo
                        isFullScreen={fullscreen}
                        statusMessage={status()}
                        ref={videoRef}
                    />
                    {isModalOpen && (
                        <ConfirmationModal
                            isOpen={isModalOpen}
                            onConfirm={handleConfirm}
                            onClose={handleCloseModal}
                            confirmLabel="Confirm End Session"
                            title="Are you sure you want to end the Live Video Assist?"
                            message="If you end the session now, you won't be able to start a new Live Video Assist session with this customer."
                        />
                    )}
                    <div className={styles.Controls}>
                        <div className={styles.SessionControls}>
                            <ScreenshotButton
                                videoRef={videoRef}
                                disabled={status() === CustomerStatus.CAMERA_OFF}
                                attributeAttachmentCount={
                                    contact?.attributes['sa-video-meeting-attachment-count']
                                }
                            />
                            {!fullscreen && (
                                <Button
                                    styling="red"
                                    title="End Session"
                                    style={{ width: '130px' }}
                                    className={styles.button}
                                    elastic
                                    onClick={handleOpenModal}
                                    disabled={videoMeeting.isDeleteInProgress}
                                >
                                    {videoMeeting.isDeleteInProgress ? (
                                        <Loader
                                            size={16}
                                            color="#000"
                                            loaderType="clip"
                                            className="sm-pad"
                                        />
                                    ) : (
                                        'End Session'
                                    )}
                                </Button>
                            )}
                        </div>
                        <Button
                            styling="white"
                            title="Full Screen"
                            className={classNames(styles.button, styles.fullscreenButton)}
                            elastic
                            onClick={() => setFullscreen(!fullscreen)}
                        >
                            {fullscreen ? (
                                <span
                                    className={['xsm-mar-right', styles.closeIcon].join(' ')}
                                ></span>
                            ) : (
                                <span
                                    className={['xsm-mar-right', styles.fullscreenIcon].join(' ')}
                                ></span>
                            )}
                        </Button>
                    </div>
                </div>
                <div className={styles.screenshotsContainer}>
                    <Screenshots
                        screenshots={videoMeeting.screenshots}
                        contactID={contact?.ID ?? ''}
                        attributeAttachmentCount={
                            contact?.attributes['sa-video-meeting-attachment-count']
                        }
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className={styles.container}>
                    <div className={styles.Message}>
                        {videoMeeting.status === 'ENDED' ? (
                            <H data-testid={'status-header'}>
                                You have ended the Live Video Assist
                            </H>
                        ) : (
                            <LoadingSession onCancelSession={handleCancel} />
                        )}
                    </div>
                </div>
                {videoMeeting.status === 'ENDED' && (
                    <div className={styles.screenshotsContainer}>
                        <Screenshots
                            screenshots={videoMeeting.screenshots}
                            contactID={contact?.ID ?? ''}
                            attributeAttachmentCount={
                                contact?.attributes['sa-video-meeting-attachment-count']
                            }
                        />
                    </div>
                )}
            </div>
        )
    }
}
