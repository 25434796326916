import { Button } from '@missionlabs/smartagent-app-components'
import { Loader } from 'components'
import { H } from 'react-accessible-headings'

import { useSelector } from 'react-redux'
import { selectMeeting } from 'store/videoMeeting/videoMeeting.reducer'
import styles from './index.module.scss'

export interface LoadingSession {
    onCancelSession: () => void
}

export const LoadingSession = (props: LoadingSession) => {
    const videoMeeting = useSelector(selectMeeting)

    return (
        <div className={styles.LoadingSession}>
            <div className={styles.loader}>
                <Loader size={64} color="#000" loaderType="clip" />
            </div>
            <H data-testid={'status-header'}>
                Waiting for {videoMeeting.smsInviteSentTo} to join...
            </H>
            <Button
                styling="red"
                title="Cancel Session"
                style={{ width: '130px' }}
                elastic
                onClick={props.onCancelSession}
                disabled={videoMeeting.isDeleteInProgress}
            >
                {videoMeeting.isDeleteInProgress ? (
                    <Loader size={16} color="#fff" loaderType="clip" className="sm-pad" />
                ) : (
                    'Cancel Session'
                )}
            </Button>
        </div>
    )
}
