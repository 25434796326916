import InfoIcon from 'images/info-error.svg'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { getAuthProviderConfig } from 'store/settings/settings.actions'
import RootState from 'store/state'
import {
    forgotPasswordConfirm,
    forgotPasswordGetCode,
    rememberedPassword,
} from 'store/user/user.actions'

import { Button, Form, Input } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'

import GreenTick from '../GreenTick.svg'
import Tick from '../Tick-fill.svg'
import { checkPasswordValidation, cognitoPasswordValidation } from '../utils'

interface Actions {
    forgotPasswordGetCode: typeof forgotPasswordGetCode
    forgotPasswordConfirm: typeof forgotPasswordConfirm
    getAuthProviderConfig(): void
    rememberedPassword(): void
}

interface Props extends ReturnType<typeof mapStateToProps> {}

const ForgotPassword: React.FC<Props & Actions> = ({
    identityManagement,
    cognitoConfig,
    userForgotPasswordGetCode,
    userForgotPasswordSuccess,
    authenticating,
    authError,
    ...actions
}) => {
    const [username, setUsername] = useState('')
    const [code, setCode] = useState('')
    const [pass, setPass] = useState('')
    const [error, setError] = useState('')

    const [longPassword, setLongPassword] = useState(false)
    const [lowercaseLetter, setLowercaseLetter] = useState(false)
    const [uppercaseLetter, setUppercaseLetter] = useState(false)
    const [digit, setDigit] = useState(false)
    // const [specialCharacter , setSpecialCharacter] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false)

    const canSubmitForm = userForgotPasswordGetCode
        ? (isValidPassword && !!code)
        : !!username

    useEffect(() => {
        actions.getAuthProviderConfig()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const { longPassword, lowercase, uppercase, digit } = checkPasswordValidation(pass)

        setLongPassword(longPassword)
        setLowercaseLetter(lowercase)
        setUppercaseLetter(uppercase)
        setDigit(digit)

        if (longPassword && lowercase && uppercase && digit) {
            setIsValidPassword(true)
        } else {
            setIsValidPassword(false)
        }
    }, [pass])

    const onSubmit = () => {
        if (userForgotPasswordGetCode) {
            if (isValidPassword) {
                return actions.forgotPasswordConfirm({ verificationCode: code, newPassword: pass })
            }
        }

        actions.forgotPasswordGetCode(username)
    }

    const validatePassword = (value: string) => {
        try {
            switch (identityManagement) {
                case 'cognito':
                    return cognitoPasswordValidation(value, cognitoConfig?.policies?.PasswordPolicy)
                default:
                    return ''
            }
        } catch (err) {
            return ''
        }
    }

    const unchecked = <img src={Tick} alt="Failed" title="Failed" width="14px" height="14px" />
    const checked = (
        <img src={GreenTick} alt="Approved" title="Approved" width="14px" height="14px" />
    )

    const FormFooter = (
        <>
            <div className="sa-login-text">
                <Button
                    className="forgot-password"
                    type="button"
                    elastic
                    onClick={() => actions.rememberedPassword()}
                >
                    Remembered your password?
                </Button>
            </div>

            <Button styling="primary" large round disabled={!canSubmitForm}>
                {authenticating ? <ClipLoader color="#fff" size={20} /> : 'Submit'}
            </Button>
        </>
    )

    const Header = (
        <div className="md-mar-bottom">
            <H className="sm-mar-bottom">Forgot Password</H>
            {error || authError ? (
                <div className="sa-login-error" aria-live="polite">
                    <img alt="Error" src={InfoIcon} />
                    <span>{error || authError || 'Oops something went wrong'}</span>
                </div>
            ) : null}
        </div>
    )
    const ForgotPasswordUsernameEntry = (
        <>
            <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                label="Username"
                className="md-mar-bottom"
                required
                value={username}
                onChange={(username: any) => setUsername(username)}
                autoComplete='username'
            />
            {FormFooter}
        </>
    )
    const ForgotPasswordTokenEntry = (
        <>
            <div>
                We have sent a verification code to the address associated with your username.
            </div>

            <div className="password-validation">
                <ul>
                    <li>
                        {longPassword ? checked : unchecked} Must be at least 8 characters
                        long
                    </li>
                    <li>
                        {lowercaseLetter ? checked : unchecked} Must include 1 lowercase
                        letter
                    </li>
                    <li>
                        {uppercaseLetter ? checked : unchecked} Must include 1 uppercase
                        letter
                    </li>
                    <li>{digit ? checked : unchecked} Must include 1 digit</li>
                    {/* <li>{specialCharacter ? checked : unchecked} Must include 1 special character </li> */}
                </ul>
            </div>

            <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                // autoFocus
                label="Verification Code"
                className="md-mar-bottom"
                required
                value={code}
                onChange={(code: any) => {
                    setCode(code)
                    setError('')
                }}
            />

            <Input
                label="New Password"
                className="md-mar-bottom"
                type="password"
                required
                onValidate={validatePassword}
                showError
                value={pass}
                onChange={(pass: any) => {
                    setPass(pass)
                    setError('')
                }}
                autoComplete='new-password'
            />
            {FormFooter}
        </>
    )

    const ForgotPasswordSuccess = (
        <>
            <div className="sa-login-success md-mar-bottom" aria-live="polite">
                <img alt="" src={GreenTick} />
                <span>Your password has successfully been reset</span>
            </div>

            <Button
                styling="primary" large round
                type="button"
                elastic
                onClick={() => actions.rememberedPassword()}
            >
                Return to login
            </Button>
        </>
    )

    return (
        <Form onSubmit={onSubmit} className="sa-login-form">
            {Header}
            { userForgotPasswordSuccess ? (
                ForgotPasswordSuccess
            ) : userForgotPasswordGetCode ? (
                ForgotPasswordTokenEntry
            ) : (
                ForgotPasswordUsernameEntry
            )}
        </Form>
    )
}

const mapStateToProps = (state: RootState) => ({
    userForgotPasswordGetCode: state.auth.userForgotPasswordGetCode,
    userForgotPasswordSuccess: state.auth.userForgotPasswordSuccess,
    authError: state.auth.authError,
    authenticating: state.auth.authenticating,
    identityManagement: state.app.identityManagement,
    cognitoConfig: state.settings.cognitoConfig,
})

export default connect(mapStateToProps, {
    forgotPasswordGetCode,
    forgotPasswordConfirm,
    getAuthProviderConfig,
    rememberedPassword,
})(ForgotPassword)
