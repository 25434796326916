import { formatDateTime } from '@missionlabs/smartagent-app-components'
import { ICallContactAttributes } from 'store/metrics/metrics.state'

import ContactState, { CTR, DPAResult, DPAState, ICustomerInputs } from './contact.state'

// Predefined contact attributes
export const contactAttributes = [
    'sa-externalID',
    'sa-agent-hungup',
    'sa-dialled-number',
    'sa-agentCheck',
    'sa-barged',
    'sa-video-meeting-id',
    'sa-video-meeting-attachment-count',
    'sa-message-attachment-count',
]

export function removeAttributesFromContact(
    existingContact: CTR,
    attributes: ICallContactAttributes,
): CTR {
    const filteredAttributes = Object.keys(existingContact.attributes)
        .filter((attributeName) => !(attributeName in attributes))
        .reduce((result: ICallContactAttributes, attributeName) => {
            result[attributeName] = existingContact.attributes[attributeName]
            return result
        }, {})

    return {
        ...existingContact,
        attributes: {
            ...filteredAttributes,
        },
    }
}

export function mergeAttributesIntoContact(
    existingContact: CTR,
    attributes: ICallContactAttributes,
): CTR {
    return {
        ...existingContact,
        attributes: {
            ...existingContact?.attributes,
            ...objectToContactAttributes(attributes),
        },
    }
}

export function mergeAcwAttributesIntoContact(
    existingContact: CTR,
    attributes: ICallContactAttributes,
): ContactState {
    return {
        ...existingContact,
        acwAttributes: objectToContactAttributes(attributes),
    }
}

export const objectToContactAttributes = (obj: Record<string, any>): ICallContactAttributes => {
    return Object.keys(obj).reduce((attributes: ICallContactAttributes, key) => {
        const propertyName = key.startsWith('sa-') ? key : `sa-${key}`
        if (Array.isArray(obj[key])) {
            attributes[propertyName] = obj[key].join(',')
        } else if (obj[key] instanceof Date) {
            attributes[propertyName] = formatDateTime(obj[key])
        } else if (typeof obj[key] === 'object') {
            return attributes
        } else {
            attributes[propertyName] = obj[key]
        }
        return attributes
    }, {})
}

export const parseContactAttributes = (attributes: ICallContactAttributes) => {
    return Object.keys(attributes).reduce((p: any, key: string) => {
        try {
            //NON sa-* attributes
            if (key.indexOf('sa-') !== 0) return p
            const parts = key.replace('sa-', '').split('-')
            let obj = p

            //DPA-*, we are handling this above
            if (key.startsWith('sa-plugins-DPA')) {
                return p
            }

            //show sa-show-plugins, sa-hide-plugins
            ;['show', 'hide'].forEach((sh) => {
                if (key.startsWith(`sa-${sh}-plugins`)) {
                    const pluginNames = attributes[key].split(',')
                    if (!obj.plugins) obj.plugins = {}

                    pluginNames.forEach((name: string) => {
                        name = name.trim()
                        if (!obj.plugins[name]) obj.plugins[name] = {}
                        obj.plugins[name] = sh
                    })
                }
            })

            // 'Old-style' show hide plugins.
            if (
                key.startsWith('sa-plugins-') &&
                ((attributes[key] as any).value === 'hide' ||
                    (attributes[key] as any).value === 'show')
            ) {
                const pluginName = key.split('sa-plugins-')[1]
                if (!obj.plugins) obj.plugins = {}
                if (!obj.plugins[pluginName]) obj.plugins[pluginName] = {}
                obj.plugins[pluginName] = (attributes[key] as any).value
                return p
            }

            //Handle sa-customer (nested objects)
            if (key.startsWith('sa-customer')) {
                parts.forEach((part, i) => {
                    if (i === parts.length - 1) {
                        obj[part] = (attributes[key] as any)?.value || attributes[key]
                    } else if (Array.isArray(obj)) {
                        const obj1 = {}
                        obj.push(obj1)
                        obj = obj1
                    } else {
                        if (!obj[part]) {
                            if (i < parts.length - 1 && !isNaN(Number(parts[i + 1]))) {
                                obj[part] = []
                            } else {
                                obj[part] = {}
                            }
                        }
                        obj = obj[part]
                    }
                })
                return p
            }

            //if we make it down here just normal sa-* attributes which we let through as is.
            const val = attributes[key]?.value || attributes[key] //If come from ccp value exists in object {value:'VALUE'} or otherwise take value from object
            const correctKey = key.replace('sa-', '')
            p[correctKey] = val
            return p
        } catch (e) {
            console.log('error getting contactObj', e)
            return p //prevents one bad attribute bringing down the whole thing.
        }
    }, {}) as ContactState
}

export function getTagsFromContactAttributes(attributes: connect.AttributeDictionary): string[] {
    return (
        Object.keys(attributes ?? {})
            // ignore sa-tag-image for legacy
            .filter((key) => key !== 'sa-tag-image' && key !== 'sa-tag-name')
            // include sa-reason and sa-tag for legacy
            .filter((key) => key.includes('sa-tag-') || key === 'sa-reason' || key === 'sa-tag')
            .map((k) => attributes[k].value)
    )
}

// Extra information view for informing agents about changes to account etc.
export function getCustomerInputs(
    attributes: connect.AttributeDictionary,
): ICustomerInputs | undefined {
    if (attributes['sa-plugins-customer-input']?.value !== 'show') {
        return
    }

    const output = Object.keys(attributes).reduce<ICustomerInputs>((inputs, attr) => {
        const match = attr.match(/sa-customer-input-(.+)-label/)

        if (match)
            return [
                ...inputs,
                {
                    label: attributes[match[0]]?.value,
                    value: attributes['sa-customer-input-' + match[1] + '-value']?.value,
                },
            ]

        return inputs
    }, [])

    return output
}

// ID&V view for automating identity verification through personal info
export function getDPAState(attributes: connect.AttributeDictionary): DPAState | undefined {
    if (attributes['sa-plugins-DPA']?.value !== 'show') {
        return
    }
    //Look for sa-DPA-*-label and sa-DPA-*-passed attributes, pull this data out into correct format before we send to redux.
    let didSomeDPAChecks = false
    let checksPassedCount = 0
    const checks = []

    function getDPAResult(attribute: any): boolean | null | 'manual-check' {
        if ((attribute && attribute.value) === 'yes') return true
        else if ((attribute && attribute.value) === 'null') return null
        else if ((attribute && attribute.value) === 'manual-check') return 'manual-check'
        else return false
    }

    for (let i = 0; i < 10; i++) {
        const labelName = 'sa-DPA-' + i + '-label'
        const label = attributes[labelName] && attributes[labelName].value
        const passedName = 'sa-DPA-' + i + '-passed'
        const passed: boolean | null | 'manual-check' = getDPAResult(attributes[passedName])
        const answerName = 'sa-DPA-' + i + '-answer'
        const answer = attributes[answerName] && attributes[answerName].value

        if (label) {
            didSomeDPAChecks = true
            checks.push({
                label,
                passed,
                passedName,
                labelName,
                answer,
            })
            if (passed) {
                checksPassedCount++
            }
        }
    }

    const agentCheckLabel = attributes['sa-agentCheck-label']?.value
    const agentCheckPassedName = 'sa-agentCheck-passed'
    const agentCheckPassed = getDPAResult(attributes[agentCheckPassedName])
    const passesRequired = attributes['sa-DPA-passes-required']?.value

    let result = 'no-check'

    if (didSomeDPAChecks && checksPassedCount >= 2) result = DPAResult['bot-passed']
    if (didSomeDPAChecks && checksPassedCount === 0) result = DPAResult['failed']

    const output: any = {
        checks,
        result,
        agentCheck: agentCheckLabel
            ? {
                  label: agentCheckLabel,
                  passed: agentCheckPassed || false,
                  passedName: agentCheckPassedName,
              }
            : undefined,
        passesRequired:
            !!passesRequired || Number(passesRequired) === 0 ? passesRequired : undefined,
    }

    // remove undefined props
    Object.keys(output).forEach((key) => output[key] === undefined && delete output[key])

    return output
}
