import React, { useEffect, useRef } from 'react'
import {
    ReactZoomPanPinchHandlers,
    TransformComponent,
    TransformWrapper,
} from 'react-zoom-pan-pinch'
import { VideoTransformState } from './interfaces/AudioPlayer'
import './video-player.scss'

interface VideoPlayerSRProps {
    isPlaying: boolean
    seekPosition: number
    setContainerRef: React.Dispatch<
        React.SetStateAction<React.MutableRefObject<HTMLDivElement | null> | null>
    >
    setVideoRef: React.Dispatch<
        React.SetStateAction<React.MutableRefObject<HTMLVideoElement | null> | null>
    >
    url: string
    setVideoZoomControls: React.Dispatch<React.SetStateAction<ReactZoomPanPinchHandlers | null>>
    transformState: {
        videoTransformState: VideoTransformState
        setVideoTransformState: React.Dispatch<React.SetStateAction<VideoTransformState>>
    }
}

const VideoPlayerSR = ({
    setContainerRef,
    setVideoRef,
    url,
    setVideoZoomControls,
    transformState,
}: VideoPlayerSRProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const { videoTransformState, setVideoTransformState } = transformState
    const { positionX, positionY, scale } = videoTransformState

    const handleTransformed = (event: any) => {
        const { positionX, positionY, scale } = event.instance.transformState

        const newTransformState = {
            positionX,
            positionY,
            scale,
        }
        setVideoTransformState(newTransformState)
    }

    useEffect(() => {
        setContainerRef(containerRef)
        setVideoRef(videoRef)
    }, [])

    return (
        <div className="video-container" ref={containerRef}>
            <TransformWrapper
                onInit={setVideoZoomControls}
                minScale={1}
                centerOnInit={false}
                onTransformed={handleTransformed}
                initialPositionX={positionX}
                initialPositionY={positionY}
                initialScale={scale}
            >
                <TransformComponent
                    wrapperStyle={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    contentStyle={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <video
                        data-testid="video-player"
                        muted
                        ref={videoRef}
                        width="600"
                        controls={false}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            maxWidth: '100%',
                        }}
                    >
                        <source data-testid="source-element" src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </TransformComponent>
            </TransformWrapper>
        </div>
    )
}

export default VideoPlayerSR
