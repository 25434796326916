import { Meeting } from '@aws-sdk/client-connect'
import { Attendee } from 'amazon-chime-sdk-js'
import { AxiosResponse } from 'axios'
import { Screenshots } from 'store/videoMeeting/videoMeeting.state'
import { makeRequest } from '.'

export interface MeetingAPIBaseRequest {
    companyID: string
    instanceID: string
    token: string
}

export interface CreateMeetingAPIRequest extends MeetingAPIBaseRequest {
    contactID: string
}

export interface SendMeetingInviteAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
    customerEndpointAddress: string
    brand: string
}

export interface DeleteMeetingAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
}

export interface GetMeetingRecordingAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
}

export type GetMeetingRecordingAPIResponse = string

export interface GetScreenshotsAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
}

export type GetScreenshotsAPIResponse = Array<{ s3Key: string; url: string }>

export interface UpdateMeetingStatusAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
    status: string
}

export interface CreateMeetingAPIResponse {
    meeting: Meeting
    agentAttendee: Attendee
}

export interface MeetingErrorAPIResponse {
    statusCode: number
    message: string
}

export interface GetScreenshotUploadURLAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
    contactID: string
}

export interface DeleteScreenshotRequest extends MeetingAPIBaseRequest {
    s3Key: string
}

export interface GetScreenshotUploadURLAPIResponse {
    s3Key: string
    presignedUrl: string
}

export interface UploadScreenshotAPIRequest {
    url: string
    imageData: string
}

const getBaseURL = ({ companyID, instanceID }: Omit<MeetingAPIBaseRequest, 'token'>) => {
    return `/video-meeting-service/companies/${companyID}/instances/${instanceID}`
}

export const createMeeting = async ({
    contactID,
    token,
    ...baseRequest
}: CreateMeetingAPIRequest): Promise<CreateMeetingAPIResponse> => {
    const response = await makeRequest<CreateMeetingAPIResponse>({
        url: `${getBaseURL(baseRequest)}/meeting/create`,
        method: 'post',
        headers: { 'X-Amz-Security-Token': token },
        data: { contactID },
    })
    return response.data
}

export const getScreenshotUploadURL = async ({
    meetingID,
    contactID,
    token,
    ...baseRequest
}: GetScreenshotUploadURLAPIRequest): Promise<GetScreenshotUploadURLAPIResponse> => {
    const response = await makeRequest<GetScreenshotUploadURLAPIResponse>({
        url: `${getBaseURL(baseRequest)}/get-screenshot-upload-url`,
        method: 'post',
        headers: { 'X-Amz-Security-Token': token },
        data: { meetingID, contactID },
    })

    return response.data
}

export const deleteScreenshot = async ({
    s3Key,
    token,
    ...baseRequest
}: DeleteScreenshotRequest): Promise<AxiosResponse<void, any>> => {
    const response = await makeRequest<void>({
        url: `${getBaseURL(baseRequest)}/meeting/screenshot`,
        method: 'delete',
        headers: { 'X-Amz-Security-Token': token },
        data: { s3Key },
    })

    return response
}

export const uploadScreenshot = async ({
    url,
    imageData,
}: UploadScreenshotAPIRequest): Promise<void> => {
    await makeRequest<void>({
        url,
        method: 'put',
        data: imageData,
        ignoreToken: true,
    })
}

export const sendMeetingInvite = async ({
    meetingID,
    customerEndpointAddress,
    brand,
    token,
    ...baseRequest
}: SendMeetingInviteAPIRequest): Promise<AxiosResponse<void, any>> => {
    const response = await makeRequest<void>({
        url: `${getBaseURL(baseRequest)}/meeting/invite/sms`,
        method: 'post',
        headers: { 'X-Amz-Security-Token': token },
        data: { meetingID, customerEndpointAddress, brand },
    })
    return response
}

export const deleteMeeting = async ({
    meetingID,
    token,
    ...baseRequest
}: DeleteMeetingAPIRequest): Promise<AxiosResponse<void, any>> => {
    const response = await makeRequest<void>({
        url: `${getBaseURL(baseRequest)}/meeting/${meetingID}`,
        headers: { 'X-Amz-Security-Token': token },
        method: 'delete',
    })
    return response
}

export const updateMeetingStatus = async ({
    meetingID,
    status,
    token,
    ...baseRequest
}: UpdateMeetingStatusAPIRequest): Promise<AxiosResponse<void, any>> => {
    const response = await makeRequest<void>({
        url: `${getBaseURL(baseRequest)}/meeting/${meetingID}`,
        headers: { 'X-Amz-Security-Token': token },
        method: 'put',
        data: { status },
    })
    return response
}

export const getMeetingRecording = async ({
    meetingID,
    token,
    ...baseRequest
}: GetMeetingRecordingAPIRequest): Promise<GetMeetingRecordingAPIResponse> => {
    const response = await makeRequest<string>({
        url: `${getBaseURL(baseRequest)}/meeting/${meetingID}/recording`,
        headers: { 'X-Amz-Security-Token': token },
        method: 'get',
    })
    return response.data
}

export const getScreenshots = async ({
    meetingID,
    token,
    ...baseRequest
}: GetScreenshotsAPIRequest): Promise<Screenshots> => {
    const response = await makeRequest<GetScreenshotsAPIResponse>({
        url: `${getBaseURL(baseRequest)}/meeting/${meetingID}/screenshots`,
        headers: { 'X-Amz-Security-Token': token },
        method: 'get',
    })

    const downloadedScreenshots = await Promise.all(
        response.data.map(async ({ s3Key, url }) => {
            const base64 = await makeRequest<string>({
                url,
                method: 'get',
                ignoreToken: true,
            })

            return {
                s3Key,
                src: base64.data,
            }
        }),
    )

    const screenshots = downloadedScreenshots.reduce(
        (screenshots, { s3Key, src }) => ({
            ...screenshots,
            [s3Key]: { src },
        }),
        {},
    )

    return screenshots
}
