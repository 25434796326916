import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Button } from '@missionlabs/smartagent-app-components'

import ChatView from './ChatView'
import TaskView from './TaskView'
import VoiceView from './VoiceView'

import { fetchInteractionAttributes } from 'store/contactSearch/contactSearch.actions'

import { IInteraction } from '../types'

import leftArrowGreen from 'images/icon-arrow-left-green.svg'

import { H, Level } from 'react-accessible-headings'
import './interaction-view.scss'

export interface Props {
    interaction: IInteraction
    clearInteraction(): void
    isWidget?: boolean
}

const InteractionView: React.FC<Props> = ({ interaction, isWidget, clearInteraction }) => {
    const dispatch = useDispatch()

    const { attributesUpdated = false, channel } = interaction

    useEffect(() => {
        if (!attributesUpdated) dispatch(fetchInteractionAttributes(interaction))
    }, [])

    return (
        <div
            className={!isWidget ? 'panel alt fixed-height interaction-view no-pad row column' : ''}
        >
            <header className="row between middle interaction-view-header">
                <H className="only-visible-to-screen-readers">Contact view</H>
                <Button
                    elastic
                    styling="blank"
                    className="interaction-view-back"
                    onClick={() => clearInteraction()}
                    icon={<img height={10} src={leftArrowGreen} alt="Back" title="Back" />}
                >
                    Back to search results
                </Button>
            </header>
            <Level>
                {channel === 'VOICE' && <VoiceView interaction={interaction} />}
                {channel === 'CHAT' && <ChatView interaction={interaction} />}
                {channel === 'TASK' && <TaskView interaction={interaction} />}
            </Level>
        </div>
    )
}

export default InteractionView
