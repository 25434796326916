import { AlertBanner, Button, Form, Input, WYSIWYG } from '@missionlabs/smartagent-app-components'
import EmojiPicker from '@missionlabs/smartagent-app-components/dist/EmojiPicker'
import QuickReplies from 'components/QuickReplies'
import { DraftEditorCommand, EditorState } from 'draft-js'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { SyntheticKeyboardEvent } from 'react-draft-wysiwyg'
import { useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { AppFeatures, FeatureConfig } from 'store/app/app.features'
import { IChatAttachmentFile } from 'store/chat/chat.state'
import { SocialMediaPlatform } from 'store/chat/chat.utils'
import RootState from 'store/state'
import useSocialChatReplyFunctionality from '../useSocialChatReplyFunctionality'
import styles from './SocialChatReplier.module.scss'

interface SocialChatReplierProps {
    message: string
    setMessage: (message: string) => void
    onSubmit: () => void
    socialMediaPlatform: SocialMediaPlatform
    messageSent: boolean
    setMessageSent: Dispatch<SetStateAction<boolean>>
    setIsQuickReplyOn: Dispatch<SetStateAction<boolean>>
    subChannel?: string
    isSubmitting?: boolean
    isSendDisabled?: boolean
    className?: string
    wysiwygProps?: WysiwygProps | undefined
}

interface WysiwygProps {
    isShowWarningMessage: boolean
    setIsShowWarningMessage: React.Dispatch<React.SetStateAction<boolean>>
    currentEditorContent: EditorState
    onEditorStateChange: (editorState: EditorState) => void
    toolbarButtons: React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
    files: IChatAttachmentFile[] | undefined
    dFile: (i: number, fileName: string, editorState: EditorState) => void
    keyBindingFn: (e: SyntheticKeyboardEvent) => 'sa-send-msg' | 'cut' | DraftEditorCommand | null
    handleKeyCommand: (command: string, editorState: EditorState) => void
}

const SocialChatReplier = ({
    message,
    setMessage,
    onSubmit,
    socialMediaPlatform,
    messageSent,
    setMessageSent,
    setIsQuickReplyOn,
    subChannel,
    isSubmitting,
    isSendDisabled,
    className,
    wysiwygProps,
}: SocialChatReplierProps) => {
    const socialMessage =
        socialMediaPlatform === 'WHATSAPP'
            ? wysiwygProps?.currentEditorContent.getCurrentContent().getPlainText('')!
            : message

    const {
        smsIsTooLongToSend,
        willSendMultipleSMSMessages,
        isSMS,
        getSocialReplyMaxLength,
        isSocialReplyTextLimitReached,
        numberOfSMSMessages,
    } = useSocialChatReplyFunctionality(socialMessage, subChannel)

    useEffect(() => {
        //adding this here because targetting it in the css file doesn't seem to work
        if (socialMediaPlatform === 'WHATSAPP' && wysiwygProps) {
            const wysiwygElement = document.querySelector<HTMLElement>(
                '.sa-wysiwyg-editor.rdw-editor-main',
            )!
            wysiwygElement.style.marginBottom = '35px'
            wysiwygElement.style.borderBottom = 'none'
        }
    }, [])

    const smsMessagingFeatureConfig = useSelector<RootState, RootState['app']['features']>(
        (state) => state.app?.features ?? [],
    ).find((feature) => feature.ID === 'sms-messaging')
        ?.config as FeatureConfig<AppFeatures.SMS_MESSAGING>

    const messageCountThreshold = smsMessagingFeatureConfig?.messageCountThreshold ?? 0

    const onSelectEmoji = (emoji: string) => setMessage(message + emoji)

    const limitWarningClass = isSocialReplyTextLimitReached() ? `${styles.warning}` : ''

    return socialMediaPlatform === 'WHATSAPP' && wysiwygProps ? (
        <div className={styles.socialWysiwyg}>
            <WYSIWYG
                isShowWarningMessage={wysiwygProps.isShowWarningMessage}
                setIsShowWarningMessage={wysiwygProps.setIsShowWarningMessage}
                editorState={wysiwygProps.currentEditorContent}
                onEditorStateChange={wysiwygProps.onEditorStateChange}
                placeholder="Enter message..."
                toolbarButtons={wysiwygProps.toolbarButtons}
                files={wysiwygProps.files}
                onDeleteFile={wysiwygProps.dFile}
                keyBindingFn={wysiwygProps.keyBindingFn}
                handleKeyCommand={wysiwygProps.handleKeyCommand}
                defaultOptionsToUse={['emoji']}
            />
            <div className={styles.textAreaCount__wysiwyg}>
                {!!getSocialReplyMaxLength() && (
                    <div className={`${styles.textAreaCount} ${limitWarningClass}`}>
                        {socialMessage.length}/{getSocialReplyMaxLength()}
                    </div>
                )}
            </div>
        </div>
    ) : (
        <Form onSubmit={onSubmit} className={`${styles.form} ${className}`}>
            <div className={styles.alertBanner}>
                {isSMS &&
                    willSendMultipleSMSMessages &&
                    numberOfSMSMessages >= messageCountThreshold && (
                        <AlertBanner>This will send {numberOfSMSMessages} SMS messages</AlertBanner>
                    )}
                {smsIsTooLongToSend && <AlertBanner>This message is too long to send</AlertBanner>}
            </div>
            <MaxLengthTextArea
                placeholder="Enter message..."
                aria-label="Enter message..."
                maxLength={getSocialReplyMaxLength()}
                textarea
                limitReached={isSocialReplyTextLimitReached()}
                rows={5}
                onChange={setMessage}
                type="text"
                value={message}
            />
            <div className={`rdw-editor-toolbar ${styles.toolbar}`}>
                <div className={styles.quickReplies}>
                    <QuickReplies
                        channel={'OutboundSMS'}
                        analyticsEnabled={true}
                        messageSent={messageSent}
                        qrChannel={socialMediaPlatform}
                        setMessageSent={setMessageSent}
                        setIsQuickReplyOn={setIsQuickReplyOn}
                        message={message}
                        setMessage={setMessage}
                    />
                </div>
                <EmojiPicker above onSelectEmoji={onSelectEmoji} />
                <Button
                    disabled={isSendDisabled || isSubmitting || smsIsTooLongToSend}
                    className={styles.sendButton}
                >
                    {isSubmitting ? <ClipLoader size={16} color="#fff" /> : 'Send'}
                </Button>
            </div>
        </Form>
    )
}

const MaxLengthTextArea: React.FC<any> = (props) => {
    const limitWarningClass = props.limitReached ? `${styles.warning}` : ''
    return (
        <>
            <Input {...props} maxLength={props.maxLength} />
            {!!props.maxLength && (
                <div className={`${styles.textAreaCount} ${limitWarningClass}`}>
                    {props.value.length}/{props.maxLength}
                </div>
            )}
        </>
    )
}

export default SocialChatReplier
