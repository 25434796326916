import type { Attachment } from '@missionlabs/smartagent-service-thread'
import type { EditorState } from 'draft-js'

export interface TasksState {
    connections: ITask[]
}

export enum TaskChannel {
    EMAIL = 'EMAIL',
    TWITTER = 'TWITTER',
    FACEBOOK = 'FACEBOOK',
    TRUSTPILOT = 'TRUSTPILOT',
    WEBFORM = 'WEBFORM',
}

interface TaskReply {
    id: string
    isProbablyJson: boolean
    text: string
}

export interface IAttachment extends Attachment {
    error?: string
}

export interface ITask {
    id: string
    status: connect.ContactStateType
    acw: boolean
    acwStartTimestamp?: number
    channel: TaskChannel
    started: number
    queueName: string
    accepting?: boolean
    description: string
    name: string
    threadID?: string
    reply?: TaskReply
    currentEditorContent: EditorState
    currentCCAddressesList?: string[]
    attachments: IAttachment[]
    includeCCInReply?: boolean
    attributes?: connect.AttributeDictionary
}

export enum TaskParticipantRole {
    AGENT = 'AGENT',
    CUSTOMER = 'CUSTOMER',
    SYSTEM = 'SYSTEM',
}

export enum TaskMessageEvent {
    TASK_EXPIRING = 'application/vnd.amazonaws.connect.event.expire.warning',
    TASK_EXPIRED = 'application/vnd.amazonaws.connect.event.expire.complete',
    TRANSFER_SUCCESS = 'application/vnd.amazonaws.connect.event.transfer.succeeded',
    TRANSFER_FAILURE = 'application/vnd.amazonaws.connect.event.transfer.failed',
    TRANSFER_INIT = 'application/vnd.amazonaws.connect.event.transfer.initiated',
}

export interface TransferTaskAction {
    id: string
    endpoint: connect.Endpoint
}

export interface UpdateConnectionCCAction {
    id: string
    ccAddressesList: string[]
}

export interface SetReplyTextAction {
    id: string
    text: string
    isProbablyJson: boolean
}

export interface UpdateConnectionEditorAction {
    id: string
    content: EditorState
}

export interface DeleteTaskAttachmentAction {
    id: string
    index: number
}

export interface SetIncludeCCAction {
    id: string
    includeCCInReply: boolean
}

export interface SetTaskAttachmentAction {
    id: string
    attachments: Attachment[]
}
