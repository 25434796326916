import { useMemo } from 'react'
import { Dropdown } from '@missionlabs/smartagent-app-components'
import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown/index'

import './time-range.scss'

interface Props {
    setTimeRange: (timeRange: 'midnight' | number) => void
    definedTime: number | string
    timeRangeOptions: Array<SADropdownOption>
}

const TimeRange = ({ timeRangeOptions, definedTime, setTimeRange }: Props) => {
    const chosenOption = useMemo(() => timeRangeOptions.find((option) => option.data === definedTime), [definedTime])

    const onChange = (option: SADropdownOption) => setTimeRange(option.data)

    const ariaLabel = chosenOption ? `Time range filter ${chosenOption.label} selected` : "Select time range"

    return (
        <div className="time-range" aria-label="Time range">
            <span className="time-range-label">Time range: </span>

            <Dropdown
                ariaLabel={ariaLabel}
                onChange={onChange}
                value={chosenOption?.label}
                options={timeRangeOptions}
            />
        </div>
    )
}

export default TimeRange
