import type { MiddlewareAPI } from '@reduxjs/toolkit'
import { differenceInSeconds } from 'date-fns'
import { batch } from 'react-redux'
import { AppFeatures, IFeature } from 'store/app/app.features'
import * as callActions from 'store/call/call.actions'
import { addToCallLog } from 'store/callLog/callLog.actions'
import * as callV2Actions from 'store/callV2/callV2.actions'
import * as chatActions from 'store/chat/chat.actions'
import {
    clearContact,
    setContact,
    updateContact,
    updateContactAttributes,
} from 'store/contact/contact.actions'
import ContactState from 'store/contact/contact.state'
import { addContact, filterContacts } from 'store/contacts/contacts.actions'
import * as metricActions from 'store/metrics/metrics.actions'
import { NOTIFICATION_TIMEOUT_IN_MILLISECONDS } from 'store/middleware/ccp/ccp.constants'
import * as ccpUtils from 'store/middleware/ccp/ccp.utils'
import { getChatTranscript } from 'store/middleware/ccp/ccp.utils'
import { attributeDictionaryToRecord } from 'store/middleware/utils'
import { createNotification } from 'store/notification/notification.actions'
import RootState from 'store/state'
import * as taskActions from 'store/tasks/tasks.actions'
import * as userActions from 'store/user/user.actions'
import {
    deleteMeeting,
    forgetMeeting,
    setMeetingStatus,
} from 'store/videoMeeting/videoMeeting.reducer'
import { removeInvalidCharacters } from 'utils'

type MiddlewareStore = MiddlewareAPI<any, RootState>

const listenToContact = (store: MiddlewareStore) => {
    window.addEventListener('message', function (event) {
        // We only accept messages from ourselves
        if (!event.data) return
        if (store.getState().auth.authenticated) return
        if (event.data.type && event.data.type === 'GOT_TOKEN') {
            store.dispatch(userActions.authenticateUser(event.data.text))
        }
    })

    let sharedContact: connect.Contact
    const sharedChatContacts: { [key: string]: connect.Contact } = {}
    const sharedTaskContacts: { [key: string]: connect.Contact } = {}

    connect.contact((contact) => {
        console.log('got contact,', contact)

        switch (contact.getType()) {
            case connect.ContactType.CHAT:
                sharedChatContacts[contact.contactId] = contact
                handleChatContact(store, contact)
                break
            case connect.ContactType.TASK:
                sharedTaskContacts[contact.contactId] = contact
                handleTaskContact(store, contact)
                break
            case connect.ContactType.QUEUE_CALLBACK:
            case connect.ContactType.VOICE:
                sharedContact = contact
                handlePhoneContact(store, contact)
                break
            default:
                console.log('Unknown contact type:', contact.getType())
        }
    })

    return [
        () => sharedContact,
        (id: string) => sharedChatContacts[id],
        (id: string) => sharedTaskContacts[id],
    ] as const
}

const handleChatContact = (store: MiddlewareStore, contact: connect.Contact) => {
    let controller: connect.AgentChatSession
    contact.onConnecting(async (contact) => {
        // chat comes in
        const connection = contact.getAgentConnection() as connect.ChatConnection
        controller = await connection.getMediaController()
        ccpUtils.monitorChatConnection(contact.contactId, store, connection)
        await store.dispatch(chatActions.addChatConnection(ccpUtils.makeChatFromContact(contact)))

        //set loading chat transcript
        store.dispatch(chatActions.setLoadingTranscripts(contact.contactId))
        const chatFeature = store
            .getState()
            .app.features.find((f) => f.ID === AppFeatures.CHAT) as IFeature<AppFeatures.CHAT>

        if (chatFeature?.config?.autoAnswer) {
            contact.accept({})
        }

        //set chat messages
        const { messages, nextToken } = await getChatTranscript(contact)
        store.dispatch(chatActions.setChatMessages({ id: contact.contactId, messages }))
        store.dispatch(
            chatActions.setNextTranscriptToken({ id: contact.contactId, token: nextToken }),
        )
    })

    contact.onIncoming((contact) => {})

    contact.onMissed((contact) => {
        controller?.cleanUpOnParticipantDisconnect()
        store.dispatch(chatActions.chatMissed(contact.contactId))

        if (store.getState().contact?.ID === contact.contactId) {
            store.dispatch(chatActions.setSelectedChat(null))
        }
    })

    contact.onConnected((contact) => {
        // chat has been answered, and then connected
        store.dispatch(chatActions.chatConnected(contact.contactId))
    })

    contact.onRefresh((contact) => {
        //contact has been updated somehow
    })

    contact.onEnded((contact) => {
        // ended by the agent or missed
        const { contact: storeContact, callLog } = store.getState()
        if (
            contact.getStatus() &&
            contact.getStatus().type !== 'error' &&
            !callLog.find(({ ID }) => ID === storeContact?.ID)
        )
            store.dispatch(addToCallLog(storeContact!))
    })

    contact.onACW((contact) => {
        store.dispatch(chatActions.chatACW(contact.contactId))
    })

    contact.onAccepted((contact) => {})

    //This necessary if the connection has been ended through the CCP but it also will run during usual acw process
    contact.onDestroy((contact) => {
        const { chat, call, user, contacts } = store.getState()
        const storeContact = contacts.find((c) => c.ID === contact.contactId)
        const isAllChatsEnded = !chat.connections.filter((chat) => chat.id !== contact.contactId)
            .length
        const endingCall = !call?.inCall
        const isAfterCallWork = user?.afterCallWork
        const isCallingCustomer = user?.status.name === connect.AgentAvailStates.CALLING_CUSTOMER
        const shouldCloseNextStatus =
            isAllChatsEnded && endingCall && !isAfterCallWork && !isCallingCustomer
        // necessary if the connection has been ended through the CCP
        batch(() => {
            store.dispatch(chatActions.clearChatConnection(contact.contactId))
            //updateContactAttributes in onDestroy event coz ACW could be closed forcibly by changing nextStatus
            const shouldSaveAttributes =
                storeContact?.acwAttributes && !!Object.values(storeContact?.acwAttributes).length
            if (shouldSaveAttributes) {
                store.dispatch(
                    updateContactAttributes(storeContact!.ID, storeContact?.acwAttributes!, true),
                )
            }
            store.dispatch(filterContacts(contact.contactId))
        })

        if (shouldCloseNextStatus) {
            batch(() => {
                // necessary for usual acw process
                store.dispatch(setContact(null))
                store.dispatch(userActions.setShowNextStatus(false))
            })
        }
    })

    contact.onRefresh((contact) => {
        // Keep the Redux contact attributes in sync with the Connect contact attributes
        store.dispatch(
            updateContactAttributes(
                contact.getContactId(),
                attributeDictionaryToRecord(contact.getAttributes())
            )
        )

        //TODO: to fix it, the type should be rejected
        const contactSnapshot = contact.toSnapshot() as any
        const agentConnection = contact.getAgentConnection()
        const agentConnectionSnapshot = contactSnapshot.contactData?.connections?.find(
            ({ connectionId }: { connectionId: string }) =>
                connectionId === agentConnection.connectionId,
        )

        if (agentConnectionSnapshot.state.type === 'rejected') {
            store.dispatch(chatActions.chatRejected(contact.contactId))
        }
    })
}

const handleTaskContact = (store: MiddlewareStore, contact: connect.Contact) => {
    contact.onConnecting(async (contact) => {
        store.dispatch(
            taskActions.addTaskConnection({
                ...ccpUtils.makeTaskFromContact(contact),
                includeCCInReply: ccpUtils.shouldIncludeCCInReplyWhenCreatingTask(store),
            }),
        )

        const taskFeature = store
            .getState()
            .app.features.find(
                (f) => f.ID === AppFeatures.TASK_DETAILS,
            ) as IFeature<AppFeatures.TASK_DETAILS>

        if (taskFeature?.config?.autoAnswer) {
            store.dispatch(taskActions.acceptTask(contact.contactId))
        }
    })

    contact.onIncoming((contact) => {})

    contact.onMissed((contact) => {
        if (contact.getStatus().type === 'rejected') {
            store.dispatch(taskActions.taskRejected(contact.contactId))
        } else {
            store.dispatch(taskActions.taskMissed(contact.contactId))
        }

        if (store.getState().contact?.ID === contact.contactId) {
            store.dispatch(taskActions.setSelectedTask(null))
        }
    })

    contact.onConnected((contact) => {
        // chat has been answered, and then connected
        store.dispatch(taskActions.taskConnected(contact.contactId))
    })

    contact.onRefresh((contact) => {
        // Keep the Redux contact attributes in sync with the Connect contact attributes
        store.dispatch(
            updateContactAttributes(
                contact.getContactId(),
                attributeDictionaryToRecord(contact.getAttributes())
            )
        )
    })

    //This necessary if the connection has been ended through the CCP but it also will run during usual acw process
    contact.onDestroy((contact) => {
        const { tasks, call, user, contacts } = store.getState()
        const storeContact = contacts.find((c) => c.ID === contact.contactId)
        const isAllTasksEnded = !tasks.connections.filter((task) => task.id !== contact.contactId)
            .length
        const endingCall = !call?.inCall
        const isAfterCallWork = user?.afterCallWork
        const shouldCloseNextStatus = isAllTasksEnded && endingCall && !isAfterCallWork
        // necessary if the connection has been ended through the CCP
        batch(() => {
            store.dispatch(taskActions.clearTaskConnection(contact.contactId))
            //updateContactAttributes in onDestroy event coz ACW could be closed forcibly by changing nextStatus
            const shouldSaveAttributes =
                storeContact?.acwAttributes && !!Object.values(storeContact?.acwAttributes).length
            if (shouldSaveAttributes) {
                store.dispatch(
                    updateContactAttributes(storeContact!.ID, storeContact?.acwAttributes!, true),
                )
                store.dispatch(taskActions.setSelectedTask(null))
            }
            store.dispatch(filterContacts(contact.contactId))
        })

        if (shouldCloseNextStatus) {
            batch(() => {
                // necessary for usual acw process
                store.dispatch(setContact(null))
                store.dispatch(userActions.setShowNextStatus(false))
            })
        }
    })

    contact.onACW((contact) => {
        store.dispatch(taskActions.taskACW(contact.contactId))
    })

    contact.onRefresh((contact) => {})

    contact.onAccepted((contact) => {})
}

const handlePhoneContact = (store: MiddlewareStore, contact: connect.Contact) => {
    contact.onConnecting(() => {
        console.log('sa-dev-connecting')
        const connections = contact.getConnections()

        const connection = connections.find((c) => {
            return c.getEndpoint().type === 'phone_number'
        }) as connect.VoiceConnection

        if (!connection?.getEndpoint().type) return

        const contactState = ccpUtils.getContactState(store.getState(), contact)

        if (contact.isInbound() && contact.getType() !== connect.ContactType.QUEUE_CALLBACK) {
            console.log('dispatching incoming call : ', contactState)

            const { forceRingTime } = store.getState().app.appConfig

            if (forceRingTime) {
                const routingProfile = store.getState().user?.routingProfile
                const routingProfileId = routingProfile?.ID.split('/').pop()
                const ringtimeInSecs = routingProfileId ? forceRingTime[routingProfileId] : null

                if (ringtimeInSecs && typeof ringtimeInSecs === 'number') {
                    setTimeout(() => {
                        if (contact.getStatus().type === connect.ContactStateType.CONNECTING) {
                            store.dispatch(callActions.rejectCall())
                        }
                    }, ringtimeInSecs * 1000)
                }
            }
            store.dispatch(
                callActions.incomingCall(
                    connection.getConnectionId(),
                    connection.getEndpoint().phoneNumber,
                    contactState,
                ),
            )
            store.dispatch(addContact(contactState))
        } else {
            const dialledNumber =
                store.getState().call?.number ??
                removeInvalidCharacters(store.getState().global.numberDialling).replace(/\s/g, '')
            store.dispatch(
                callActions.outboundCall(connection.getConnectionId(), dialledNumber, contactState),
            )
            store.dispatch(addContact(contactState))
            if (
                store
                    .getState()
                    .app.appConfig!.outboundNumberMappings?.some(
                        (mapping) => mapping.number === dialledNumber,
                    ) ||
                store.getState().app.appConfig.SIPgatewayConfig
            ) {
                store.dispatch(
                    updateContactAttributes(contact.contactId, {
                        'sa-dialled-number': dialledNumber,
                    }),
                )
            }
            if (store.getState().app.instance!.allocateOutbound) {
                store.dispatch(
                    updateContactAttributes(contact.contactId, {
                        'sa-dialled-number': dialledNumber,
                    }),
                )
            }
        }
        ccpUtils.monitorConnection(store, connection)
    })

    contact.onMissed((contact) => {
        if (contact.isInbound()) {
            store.dispatch(callActions.missedCall())
        }
    })

    contact.onIncoming((contact) => {
        if (contact.getType() === connect.ContactType.QUEUE_CALLBACK) {
            const connection = contact.getConnections().find((c) => {
                return c.getEndpoint().type === 'phone_number'
            })

            if (!connection) return
            const contactState = ccpUtils.getContactState(store.getState(), contact)

            contactState.initiationMethod = 'CALLBACK'

            console.log('dispatching incoming callback: ', contactState)

            store.dispatch(
                callActions.callbackCall(
                    connection.getConnectionId(),
                    connection.getEndpoint().phoneNumber,
                    contactState,
                ),
            )
            store.dispatch(addContact(contactState))

            if (store.getState().app.appConfig.autoAnswerCallbacks) {
                store.dispatch(callActions.acceptCall())
            }
        }
    })

    contact.onAccepted(() => {
        // Stop task ringtone playing during voice call by declining incoming task when user accepts voice call
        const incomingTask = store
            .getState()
            .tasks?.connections.find((c) => c.status === connect.ContactStateType.CONNECTING)
        if (incomingTask) {
            store.dispatch(taskActions.declineTask(incomingTask?.id))
        }

        const connectionId = contact.getActiveInitialConnection()?.getConnectionId()
        if (!connectionId) {
            return
        }
        store.dispatch(callActions.connectionConnecting(connectionId))
    })

    contact.onRefresh((contact) => {
        const connectContactState = ccpUtils.getContactState(store.getState(), contact)
        const currentAttributesOnContactInStore = store.getState().contact?.attributes ?? {}

        const { contact: contactState } = store.getState()

        const connectedStateTime = contactState?.connectedToAgentTimestamp

        const calculateQueueTime = differenceInSeconds(
            connectedStateTime ? connectedStateTime : 0,
            contact.getQueueTimestamp()?.getTime(),
        )

        const getQueueTime = contact.isInbound() && connectedStateTime ? calculateQueueTime : 0

        // Keep the Redux contact attributes in sync with the Connect contact attributes
        const currentContact: ContactState = {
            ...connectContactState,
            inQueueDuration: getQueueTime,
            attributes: { ...currentAttributesOnContactInStore, ...connectContactState.attributes },
        }
        if (contactState?.ID) {
            store.dispatch(updateContact(currentContact))
        } else {
            store.dispatch(setContact(currentContact))
        }

        const isEnhancedMonitoringEnabled = ccpUtils.getIsEnhancedMonitoringEnabled(store)

        if (!isEnhancedMonitoringEnabled) return

        const { callV2 } = store.getState()

        const { connections: connectionsV2 } = callV2

        const contactConnections = contact.getConnections() as connect.VoiceConnection[]

        store.dispatch(callActions.updateConnections(contactConnections))

        // Only update the Call State V2 connections once they have been initialised in the onConnected event listener
        if (connectionsV2.length === 0) return

        store.dispatch(callV2Actions.updateConnections(contactConnections))

        // Emit notifications for a barger joining or leaving the call
        const { isBargerJoining, isBargerLeaving } = ccpUtils

        if (isBargerJoining(connectionsV2, contactConnections)) {
            store.dispatch(
                createNotification({
                    header: 'Information',
                    type: 'info',
                    text: 'Your call has been barged.',
                    closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                }),
            )
        }

        if (isBargerLeaving(connectionsV2, contactConnections)) {
            store.dispatch(
                createNotification({
                    header: 'Information',
                    type: 'info',
                    text: 'The barger has left the call.',
                    closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                }),
            )
        }

        // Only run the customer disconnection notification logic if the user is in a multi-party call
        if (connectionsV2.length < 3) return

        const hasCustomerDisconnected = ccpUtils.hasCustomerDisconnected(connectionsV2, contact)
        const hasReachedConnectionLimit = ccpUtils.hasReachedConnectionLimit(
            connectionsV2,
            contactConnections,
        )
        const areYouMonitoringOrBarging = ccpUtils.areYouMonitoringOrBarging(contactConnections)

        if (hasCustomerDisconnected) {
            store.dispatch(
                createNotification({
                    header: 'Information',
                    type: 'info',
                    text: 'The customer has disconnected from the call.',
                    closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                }),
            )
        }

        // Emit an information notification if the connection limit has been reached and you are not monitoring or barging
        if (hasReachedConnectionLimit && !areYouMonitoringOrBarging) {
            store.dispatch(
                createNotification({
                    header: 'Information',
                    type: 'info',
                    text: 'The maximum number of participants has been reached.',
                    closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                }),
            )
        }
    })

    contact.onConnected((contact) => {
        store.dispatch(callActions.callStarted())

        const initialConnection = contact.getInitialConnection() as connect.VoiceConnection
        const agentConnection = contact.getAgentConnection() as connect.VoiceConnection
        const initialConnectionId = initialConnection.getConnectionId()

        if (agentConnection.getType() === 'monitoring') {
            store.dispatch(callActions.initiateLegacyMonitoring(contact))
        } else {
            store.dispatch(callActions.connectionStarted(initialConnectionId))
            store.dispatch(
                updateContact({ ...store.getState().contact, started: new Date() } as ContactState),
            )
        }

        const isEnhancedMonitoringEnabled = ccpUtils.getIsEnhancedMonitoringEnabled(store)

        if (!isEnhancedMonitoringEnabled) return

        store.dispatch(callV2Actions.initialiseConnections(contact))
    })

    contact.onEnded((contact) => {
        const {
            contact: storeContact,
            videoMeeting: { meeting: videoMeeting },
        } = store.getState()

        // if an agent has an incoming callback but at the same time has missed a regular call
        // onEnded event will be triggered for missed incoming call and clear current state for callback call
        // this may cause a bug agent is unable to accept call
        if (storeContact && contact.contactId !== storeContact?.ID) return

        //remove video meeting store
        if (videoMeeting) {
            store.dispatch(
                setMeetingStatus({
                    status: 'ENDED',
                }),
            )
            store.dispatch(deleteMeeting())
        }
        store.dispatch(forgetMeeting())

        //getStatus will error if contact has been destroyed
        try {
            if (contact.getStatus() && storeContact?.connectedToAgentTimestamp) {
                store.dispatch(addToCallLog(storeContact))
            }
            //Missed call or failed to connect - need to clear contact
            if (contact.getStatus().type === 'error') {
                store.dispatch(clearContact())
                store.dispatch(filterContacts(contact.contactId))
            }
        } catch (ex) {
            store.dispatch(clearContact())
            store.dispatch(filterContacts(contact.contactId))
        }
        if (!store.getState().call) return

        //If callback has ended dont call callEnded event
        //if (_contact.getType() === connect.ContactType.QUEUE_CALLBACK) return;
        store.dispatch(callActions.callEnded())
        store.dispatch(metricActions.stopMonitorAgent())
    })

    contact.onDestroy((contact) => {
        const { tasks, chat, contacts } = store.getState()
        const storeContact = contacts.find((c) => c.ID === contact.contactId)
        const isAllChatsEnded = !chat.connections.length
        const isAllTasksEnded = !tasks.connections.length
        // necessary if the connection has been ended through the CCP
        batch(() => {
            store.dispatch(userActions.afterCallWorkEnd())
            //updateContactAttributes in onDestroy event coz ACW could be closed forcibly by changing nextStatus
            const shouldSaveAttributes =
                storeContact?.acwAttributes && !!Object.values(storeContact?.acwAttributes).length
            if (shouldSaveAttributes) {
                store.dispatch(
                    updateContactAttributes(storeContact!.ID, storeContact?.acwAttributes!, true),
                )
            }
            store.dispatch(filterContacts(contact.contactId))
        })

        if (isAllChatsEnded && isAllTasksEnded) {
            batch(() => {
                // necessary for usual acw process
                store.dispatch(setContact(null))
                store.dispatch(userActions.setShowNextStatus(false))
            })
        }
        const isEnhancedMonitoringEnabled = ccpUtils.getIsEnhancedMonitoringEnabled(store)

        if (isEnhancedMonitoringEnabled) {
            store.dispatch(callV2Actions.resetCallState())
        }
    })
}

export default listenToContact
