import { Button } from '@missionlabs/smartagent-app-components'
import React from 'react'

import MuteCh1Off from './MuteCh1Off.svg'
import MuteCh1On from './MuteCh1On.svg'
import { Waveform } from './Waveform'
import { IWaveformChannel } from './interfaces/AudioPlayer'

export interface Props {
    channel?: IWaveformChannel
    currentTimePercentage: number
    currentTimeSeconds: number
    maxTimeSeconds: number
    id: number
    muted: boolean
    width: number
    isPlaying: boolean
    onSeek(e: React.MouseEvent | React.KeyboardEvent, seek?: boolean): Promise<number | undefined>
    muteChannel(ch: number): void
}

export const Track: React.FC<Props> = ({
    channel,
    currentTimePercentage,
    currentTimeSeconds,
    maxTimeSeconds,
    id,
    muted,
    isPlaying,
    onSeek,
    width,
    muteChannel,
}) => {
    const getMutedIcon = () => {
        return muted ? MuteCh1On : MuteCh1Off
    }

    const getAltString = () => {
        if (id === 1) return muted ? 'Unmute customer' : 'Mute customer'

        return muted ? 'Unmute agent' : 'Mute agent'
    }

    return (
        <div className={`track track-${id}`}>
            <Button
                onClick={() => muteChannel(id)}
                data-testid={`channel-${id}-mute-button`}
                className="mute"
                title={getAltString()}
            >
                <img src={getMutedIcon()} alt={getAltString()} title={getAltString()} />
            </Button>

            <Waveform
                id={id}
                width={width}
                channel={channel}
                currentTimePercentage={currentTimePercentage}
                currentTimeSeconds={currentTimeSeconds}
                maxTimeSeconds={maxTimeSeconds}
                onSeek={onSeek}
                isPlaying={isPlaying}
            />
        </div>
    )
}
