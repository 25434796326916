import { PropsWithChildren } from 'react'
import { H } from 'react-accessible-headings'

import classNames from 'classnames'
import styles from './index.module.scss'

export type ScreenshotsHeadingProps = PropsWithChildren<{
    count: number
}>

export const ScreenshotsHeading = ({ count, children }: ScreenshotsHeadingProps) => {
    return (
        <div className={classNames(styles.ScreenshotsHeading, children && styles.WithMargin)}>
            <H>Screenshots ({count})</H>
            <div className={styles.ActionButtons}>{children}</div>
        </div>
    )
}
